/* eslint-disable no-restricted-syntax */
import { Dimensions } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';

import limitPrecision from 'editor/src/util/limitPrecision';

interface PageLayout {
  slots: Slot[];
  direction: 'vertical' | 'horizontal';
}

interface Slot {
  image: GalleryImage;
  x: number;
  y: number;
  width: number;
  height: number;
}

type Row = {
  images: { image: GalleryImage; aspectRatio: number; croppedHeight: number; croppedWidth: number }[];
  totalAspectRatio: number;
  rowHeight: number;
};

type Column = {
  images: { image: GalleryImage; aspectRatio: number; croppedHeight: number; croppedWidth: number }[];
  totalAspectRatio: number;
  columnWidth: number;
};

function createRowBasedLayout(
  images: GalleryImage[],
  innerContainerWidth: number,
  innerContainerHeight: number,
): Slot[] {
  const rows: Row[] = [];

  // Calculate optimal number of rows based on container dimensions
  const containerAspectRatio = innerContainerWidth / innerContainerHeight;
  const estimatedOptimalRows = Math.round(Math.sqrt(images.length / containerAspectRatio));
  const targetImagesPerRow = Math.ceil(images.length / estimatedOptimalRows);

  const currentImages = [...images];

  while (currentImages.length > 0) {
    const numImagesForRow = Math.min(targetImagesPerRow, currentImages.length);
    const rowImages = currentImages.splice(0, numImagesForRow);

    const rowAspectRatioSum = rowImages.reduce((sum, img) => sum + img.width / img.height, 0);
    const rowHeight = innerContainerWidth / rowAspectRatioSum;

    rows.push({
      images: rowImages.map((image) => ({
        image,
        aspectRatio: image.width / image.height,
        croppedHeight: rowHeight,
        croppedWidth: rowHeight * (image.width / image.height),
      })),
      totalAspectRatio: rowAspectRatioSum,
      rowHeight,
    });
  }

  const totalRowHeight = rows.reduce((sum, row) => sum + row.rowHeight, 0);
  const heightScaleFactor = innerContainerHeight / totalRowHeight;

  const maxRowWidth = Math.max(
    ...rows.map((row) => {
      const rowWidth = row.images.reduce((sum, img) => sum + img.croppedWidth * heightScaleFactor, 0);
      return rowWidth;
    }),
  );

  const widthScaleFactor = maxRowWidth > innerContainerWidth ? innerContainerWidth / maxRowWidth : 1;
  const scaleFactor = Math.min(heightScaleFactor * widthScaleFactor, heightScaleFactor);

  const slots: Slot[] = [];
  let yOffset = 0;

  rows.forEach((row) => {
    let xOffset = 0;
    const adjustedRowHeight = row.rowHeight * scaleFactor;

    row.images.forEach((imageData) => {
      const adjustedWidth = imageData.croppedWidth * scaleFactor;
      const imageAspectRatio = imageData.image.width / imageData.image.height;
      const slotAspectRatio = adjustedWidth / adjustedRowHeight;

      let finalWidth = adjustedWidth;
      let finalHeight = adjustedRowHeight;

      if (imageAspectRatio > slotAspectRatio) {
        finalHeight = adjustedRowHeight;
        finalWidth = finalHeight * imageAspectRatio;
      } else {
        finalWidth = adjustedWidth;
        finalHeight = finalWidth / imageAspectRatio;
      }

      const xCenter = xOffset + adjustedWidth / 2;
      const yCenter = yOffset + adjustedRowHeight / 2;

      slots.push({
        image: imageData.image,
        x: xCenter - finalWidth / 2,
        y: yCenter - finalHeight / 2,
        width: finalWidth,
        height: finalHeight,
      });

      xOffset += adjustedWidth;
    });

    yOffset += adjustedRowHeight;
  });

  return slots;
}

function createColumnBasedLayout(
  images: GalleryImage[],
  innerContainerWidth: number,
  innerContainerHeight: number,
): Slot[] {
  const columns: Column[] = [];

  // Calculate optimal number of columns based on container dimensions
  const containerAspectRatio = innerContainerWidth / innerContainerHeight;
  const estimatedOptimalColumns = Math.round(Math.sqrt(images.length * containerAspectRatio));
  const targetImagesPerColumn = Math.ceil(images.length / estimatedOptimalColumns);

  const currentImages = [...images];

  while (currentImages.length > 0) {
    const numImagesForColumn = Math.min(targetImagesPerColumn, currentImages.length);
    const columnImages = currentImages.splice(0, numImagesForColumn);

    const columnAspectRatioSum = columnImages.reduce((sum, img) => sum + img.height / img.width, 0);
    const columnWidth = innerContainerHeight / columnAspectRatioSum;

    columns.push({
      images: columnImages.map((image) => ({
        image,
        aspectRatio: image.width / image.height,
        croppedWidth: columnWidth,
        croppedHeight: columnWidth * (image.height / image.width),
      })),
      totalAspectRatio: columnAspectRatioSum,
      columnWidth,
    });
  }

  const totalColumnWidth = columns.reduce((sum, column) => sum + column.columnWidth, 0);
  const widthScaleFactor = innerContainerWidth / totalColumnWidth;

  const maxColumnHeight = Math.max(
    ...columns.map((column) => {
      const columnHeight = column.images.reduce((sum, img) => sum + img.croppedHeight * widthScaleFactor, 0);
      return columnHeight;
    }),
  );

  const heightScaleFactor = maxColumnHeight > innerContainerHeight ? innerContainerHeight / maxColumnHeight : 1;
  const scaleFactor = Math.min(heightScaleFactor * widthScaleFactor, widthScaleFactor);

  const slots: Slot[] = [];
  let xOffset = 0;

  columns.forEach((column) => {
    let yOffset = 0;
    const adjustedColumnWidth = column.columnWidth * scaleFactor;

    column.images.forEach((imageData) => {
      const adjustedHeight = imageData.croppedHeight * scaleFactor;
      const imageAspectRatio = imageData.image.width / imageData.image.height;
      const slotAspectRatio = adjustedColumnWidth / adjustedHeight;

      let finalWidth = adjustedColumnWidth;
      let finalHeight = adjustedHeight;

      if (imageAspectRatio > slotAspectRatio) {
        finalHeight = adjustedHeight;
        finalWidth = finalHeight * imageAspectRatio;
      } else {
        finalWidth = adjustedColumnWidth;
        finalHeight = finalWidth / imageAspectRatio;
      }

      const xCenter = xOffset + adjustedColumnWidth / 2;
      const yCenter = yOffset + adjustedHeight / 2;

      slots.push({
        image: imageData.image,
        x: xCenter - finalWidth / 2,
        y: yCenter - finalHeight / 2,
        width: finalWidth,
        height: finalHeight,
      });

      yOffset += adjustedHeight + 0;
    });

    xOffset += adjustedColumnWidth + 0;
  });

  return slots;
}

function scaleLayoutToFill(slots: Slot[], containerWidth: number, containerHeight: number, margin: number): Slot[] {
  const bounds = slots.reduce(
    (acc, slot) => ({
      minX: Math.min(acc.minX, slot.x),
      maxX: Math.max(acc.maxX, slot.x + slot.width),
      minY: Math.min(acc.minY, slot.y),
      maxY: Math.max(acc.maxY, slot.y + slot.height),
    }),
    { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity },
  );

  const currentWidth = bounds.maxX - bounds.minX;
  const currentHeight = bounds.maxY - bounds.minY;

  return slots.map((slot) => {
    const relativeX = (slot.x - bounds.minX) / currentWidth;
    const relativeY = (slot.y - bounds.minY) / currentHeight;
    const relativeWidth = slot.width / currentWidth;
    const relativeHeight = slot.height / currentHeight;

    const availableWidth = containerWidth - 2 * margin;
    const availableHeight = containerHeight - 2 * margin;

    const x = Math.max(0, limitPrecision(margin + relativeX * availableWidth, 1));
    const y = Math.max(0, limitPrecision(margin + relativeY * availableHeight, 1));
    let width = limitPrecision(relativeWidth * availableWidth, 1);
    let height = limitPrecision(relativeHeight * availableHeight, 1);

    if (x + width > containerWidth) {
      width = containerWidth - x;
    }

    if (y + height > containerHeight) {
      height = containerHeight - y;
    }

    return {
      image: slot.image,
      x,
      y,
      width,
      height,
    };
  });
}

function addGapsToLayout(slots: Slot[], gap: number, containerWidth: number, containerHeight: number): Slot[] {
  const halfGap = gap / 2;

  slots.forEach((slot) => {
    // Add gap from left if not at left edge
    if (slot.x > 0) {
      slot.x += halfGap;
      slot.width -= halfGap;
    }

    // Add gap from right if not at right edge
    if (slot.x + slot.width < containerWidth) {
      slot.width -= halfGap;
    }

    // Add gap from top if not at top edge
    if (slot.y > 0) {
      slot.y += halfGap;
      slot.height -= halfGap;
    }

    // Add gap from bottom if not at bottom edge
    if (slot.y + slot.height < containerHeight) {
      slot.height -= halfGap;
    }
  });

  return slots;
}

function calculateCroppingRatio(slot: Slot, originalImage: GalleryImage): number {
  if (originalImage.width / originalImage.height > slot.width / slot.height) {
    const imageWidthWithScale = slot.width;
    const imageHeightWithScale = imageWidthWithScale * (originalImage.height / originalImage.width);
    return (slot.height - imageHeightWithScale) / imageHeightWithScale;
  }
  const imageHeightWithScale = slot.height;
  const imageWidthWithScale = imageHeightWithScale * (originalImage.width / originalImage.height);
  return (slot.width - imageWidthWithScale) / imageWidthWithScale;
}

function calculateLayoutCroppingRatio(slots: Slot[]): number {
  return (
    slots.reduce((sum, slot) => {
      const croppingRatio = calculateCroppingRatio(slot, slot.image);
      return sum + croppingRatio;
    }, 0) / slots.length
  );
}

/**
 * Creates a layout for a list of images that fills a page while maintaining relative proportions.
 * The process follows these steps:
 *
 * 1. Try two different layout approaches:
 *    - Row-based: Organizes images in horizontal rows
 *    - Column-based: Organizes images in vertical columns
 *
 * 2. Choose the better layout:
 *    - Compare scale factors of both layouts
 *    - Select the one requiring less scaling (closer to 1.0)
 *
 * 3. Scale the chosen layout to fill the page:
 *    - Calculate relative positions of all slots
 *    - Scale to fill available space while maintaining proportions
 *    - Ensure no slots exceed page boundaries
 *
 * 4. Add gaps between images:
 *    - Add half-gap spacing between adjacent images
 *    - Adjust dimensions to maintain overall layout size
 *
 * @param images - List of images to layout
 * @param pageDimensions - Width and height of the target page
 * @param gap - Space between images (default: 1)
 * @param margin - Space around the edges (default: 0)
 * @returns PageLayout object containing slots for each image and the layout direction
 */
function createPageLayout(
  images: GalleryImage[],
  pageDimensions: Dimensions,
  options?: {
    gap?: number;
    margin?: number;
  },
): PageLayout {
  const { width: containerWidth, height: containerHeight } = pageDimensions;
  const { gap = 1, margin = 0 } = options ?? {};
  const innerContainerWidth = containerWidth - 2 * margin;
  const innerContainerHeight = containerHeight - 2 * margin;

  const rowLayoutSlots = createRowBasedLayout(images, innerContainerWidth, innerContainerHeight);
  const columnLayoutSlots = createColumnBasedLayout(images, innerContainerWidth, innerContainerHeight);

  // Scale both layouts to get accurate cropping comparisons
  const scaledRowSlots = scaleLayoutToFill(rowLayoutSlots, containerWidth, containerHeight, margin);
  const scaledColumnSlots = scaleLayoutToFill(columnLayoutSlots, containerWidth, containerHeight, margin);

  // Calculate cropping ratio (lower score means less cropping)
  const rowCroppingRatio = calculateLayoutCroppingRatio(scaledRowSlots);
  const columnCroppingRatio = calculateLayoutCroppingRatio(scaledColumnSlots);

  let slots = rowCroppingRatio <= columnCroppingRatio ? scaledRowSlots : scaledColumnSlots;
  slots = addGapsToLayout(slots, gap, containerWidth, containerHeight);

  return {
    slots,
    direction: slots === scaledRowSlots ? 'vertical' : 'horizontal',
  };
}

/**
 * Mirrors the layout of a page based on the layout direction.
 * @param layout - The layout to mirror.
 * @param containerWidth - The width of the container.
 * @param containerHeight - The height of the container.
 * @returns The mirrored layout.
 */
function mirrorLayout(layout: PageLayout, containerWidth: number, containerHeight: number): PageLayout {
  const mirroredSlots = layout.slots.map((slot) => {
    if (layout.direction === 'vertical') {
      // For vertical layouts, mirror horizontally (flip x)
      return {
        ...slot,
        x: containerWidth - (slot.x + slot.width),
      };
    }
    // For horizontal layouts, mirror vertically (flip y)
    return {
      ...slot,
      y: containerHeight - (slot.y + slot.height),
    };
  });

  return {
    slots: mirroredSlots,
    direction: layout.direction,
  };
}

// Export both functions
export { mirrorLayout };
export default createPageLayout;
