import { captureException } from '@sentry/react';

import { DesignData } from 'editor/src/store/design/types';

const designStructureToSpreadStructure = (designData: DesignData, spreadIndex: number): DesignData => {
  const spread = designData.spreads.find((_, idx) => idx === spreadIndex);
  if (!spread) {
    const error = new Error(`Save design template. Can not find spread by index ${spreadIndex}`);
    captureException(error);

    throw error;
  }

  const newDesignData: DesignData = {
    product_uid: designData.product_uid,
    spreads: [{ ...spread, pages: [{ ...spread.pages[0], page_nr: 1 }] }],
  };

  return newDesignData;
};

export default designStructureToSpreadStructure;
