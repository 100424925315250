import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateGridElementOnAllSpreadsOperation from 'editor/src/store/design/operation/updateGridElementOnAllSpreadsOperation';
import type { MediaGridInfo } from 'editor/src/store/design/types';
import selectFirstSpreadWithGridOperation from 'editor/src/store/editor/operation/selectFirstSpreadWithGridOperation';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import loadGridDesignFonts from 'editor/src/store/fonts/utils/loadGridDesignFonts';
import { setGridDesignsLocaleAction } from 'editor/src/store/gridConfig/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import type { RootState } from 'editor/src/store/index';

import { setPageBackgroundColorAction } from '../../slice';

const updateAllGridElementsOperation =
  (override: Partial<Exclude<MediaGridInfo, 'firstDayOfWeek'>>) =>
  async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const gridDesigns = state.gridDesigns.grids;
    const fonts = state.fonts.availableFonts;

    if (override.designName) {
      const design = gridDesigns.find((design) => design.name === override.designName);
      design && (await loadGridDesignFonts(design, fonts));
    }

    batch(() => {
      dispatch(saveUndoRedoStateOperation('update grid'));

      if (override.designName) {
        const design = gridDesigns.find((design) => design.name === override.designName);
        design &&
          state.design.designData?.spreads.forEach((_, spreadIndex) => {
            dispatch(
              setPageBackgroundColorAction({
                spreadIndex,
                pageIndex: undefined,
                backgroundColor: design.presentationBackgroundColor,
              }),
            );
          });
      }

      override.locale && dispatch(setGridDesignsLocaleAction(override.locale));

      dispatch(
        updateGridElementOnAllSpreadsOperation({
          gridDesigns,
          fonts,
          override,
        }),
      );
      dispatch(selectFirstSpreadWithGridOperation());
    });
  };

export default updateAllGridElementsOperation;
