import cn from 'classnames';
import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import hasSidebarTab from 'editor/src/store/editorModules/sidebar/selector/hasSidebarTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getVisibleWarningsCount from 'editor/src/store/editorModules/warnings/selector/getVisibleWarningsCount';
import { useSelector } from 'editor/src/store/hooks';

import { LAYOUT_TAG_GRID } from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';

import ControlButton from 'editor/src/component/ControlButton';
import Badge from 'editor/src/component/DesktopSidebar/Tabs/Tab/Badge';
import IconMoreActive from 'editor/src/component/Icon/IconMoreActive';
import HelpMenuContent from 'editor/src/component/TopMenuMobile/OverlayMenu/HelpMenuContent';
import MoreMenuContent from 'editor/src/component/TopMenuMobile/OverlayMenu/MoreMenuContent';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import styles from './index.module.scss';

function ButtonMenuMore() {
  const moreMenu = useMobileMenu();
  const helpMenu = useMobileMenu();
  const { settingsTabExists, warningsTabExists, warningsCount, showHelpCenter, showSaveTemplate, showSwitchTemplate } =
    useSelector(
      (state) => ({
        settingsTabExists: hasSidebarTab(state, TAB_NAMES.SETTINGS),
        warningsTabExists: hasSidebarTab(state, TAB_NAMES.WARNINGS),
        showSaveTemplate:
          hasSidebarTab(state, TAB_NAMES.DESIGN_TEMPLATES) &&
          !getSpread(state, getCurrentSpreadIndex(state))?.tags?.includes(LAYOUT_TAG_GRID),
        warningsCount: getVisibleWarningsCount(state),
        showHelpCenter: !!state.hostSettings.enableHelperCenter,
        showSwitchTemplate: state.hostSettings.enableSwitchDesignTemplates,
      }),
      shallowEqual,
    );

  const handleClick = () => {
    moreMenu.open();
  };

  const openHelpMenu = useCallback(() => {
    moreMenu.close();
    helpMenu.open();
  }, [helpMenu.open, moreMenu.close]);

  if ((!warningsTabExists || !warningsCount) && !settingsTabExists && !showHelpCenter) {
    return null;
  }

  return (
    <div className={styles.buttonMenuMoreWrap}>
      <ControlButton onClick={handleClick} stopPropagation className={cn(styles.buttonMenuMore, 'cy-button-more')}>
        {warningsCount ? <Badge className={styles.badge}>{warningsCount}</Badge> : null}
        <IconMoreActive />
      </ControlButton>
      {moreMenu.render(
        <MoreMenuContent
          close={moreMenu.close}
          openHelpMenu={openHelpMenu}
          showSettings={settingsTabExists}
          showWarnings={warningsTabExists}
          warningsCount={warningsCount}
          showHelpCenter={showHelpCenter}
          showSaveTemplate={showSaveTemplate}
          showSwitchTemplate={showSwitchTemplate}
        />,
      )}
      {helpMenu.render(<HelpMenuContent close={helpMenu.close} />)}
    </div>
  );
}

export default React.memo(ButtonMenuMore);
