import cn from 'classnames';
import React, { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { LoaderType } from 'editor/src/store/app/types';
import getCurrentSpreadGroup from 'editor/src/store/design/selector/getCurrentSpreadGroup';
import getFocusedMediaElementUuidSelector from 'editor/src/store/editor/selector/getFocusedMediaElementUuidSelector';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import getTextEditMode from 'editor/src/store/editor/selector/getTextEditMode';
import isWallpaperEmptyScreenVisible from 'editor/src/store/editor/selector/isWallpaperEmptyScreenVisible';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getBackgroundColor from 'editor/src/store/hostSettings/selector/getBackgroundColor';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import useCommonCanvasUtils, { CANVAS_ID } from 'editor/src/util/useCommonCanvasUtils';
import { FabricCanvasContext } from 'editor/src/util/useFabricCanvas';
import { FabricUtilsContext } from 'editor/src/util/useFabricUtils';
import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import Loader from 'editor/src/component/Loader';

import PageSwitcher from '../PageSwitcher';

import Alert from './Alert';
import EditorAreaControls from './EditorAreaControls';
import ElementOperationOverlay from './ElementOperationOverlay';
import EmptyContentPlaceholder from './EmptyContentPlaceholder';
import EmptyPlaceholder from './EmptyPlaceholder';
import FileDropZone from './FileDropZone';
import Gestures from './Gestures';
import Grid from './Grid';
import PerspectiveLines from './PerspectiveLines';
import Spread from './Spread';
import RollerStripes from './Spread/RollerStripes';
import Rulers from './Spread/Rulers';
import zIndex from './Spread/zIndex';
import useKeyboardShortcuts from './useKeyboardShorcuts';
import { SpreadDisplayContext } from './useSpreadDisplay';
import WallpaperEmptyPlaceholder from './WallpaperEmptyPlaceholder';

import styles from './index.module.scss';

export interface Props {
  isMobile: boolean;
  areSpreadsVisible?: boolean;
}

function EditorArea({ isMobile, areSpreadsVisible = true }: Props) {
  const dispatch = useDispatch();
  const { canvasDiv, fabricCanvas, canvasRotation, fabricUtils, dimensions, spreadsInfo, displayInfo } =
    useCommonCanvasUtils(isMobile, false);

  const {
    backgroundColor,
    showEmpty,
    showRuler,
    showRollerStripes,
    showGrid,
    designDimensions,
    textEditMode,
    showPerspectiveLines,
    showCanvasOperationHelper,
    showWallpaperEmptyScreen,
    isFocusedMode,
    showEmptyDesignPlaceholder,
  } = useSelector(
    (state) => ({
      backgroundColor: getBackgroundColor(state),
      showEmpty: state.variants.isVariantFlow && !state.variants.selectedGroupKey,
      designDimensions: state.design.designData?.dimensions,
      showRuler: getSettingsValue(state, SettingsProperty.showRulers) && getHostSetting(state, 'allowShowRulersChange'),
      showRollerStripes:
        getSettingsValue(state, SettingsProperty.showRollerStripes) &&
        getHostSetting(state, 'allowShowRollerStripesChange'),
      showPerspectiveLines:
        getSettingsValue(state, SettingsProperty.showPerspectiveLines) &&
        getHostSetting(state, 'allowShowPerspectiveLines'),
      showGrid: getSettingsValue(state, SettingsProperty.showGrid),
      textEditMode: getTextEditMode(state),
      currentSpreadGroup: getCurrentSpreadGroup(state),
      showCanvasOperationHelper: getHostSetting(state, 'showCanvasOperationHelper'),
      showWallpaperEmptyScreen: isWallpaperEmptyScreenVisible(state),
      isFocusedMode: getFocusedMediaElementUuidSelector(state),
      showEmptyDesignPlaceholder: state.hostSettings.showEmptyDesignPlaceholder,
    }),
    shallowEqual,
  );

  useOnClickOutside(canvasDiv, () => dispatch(setTextEditModeOperation(false)), !textEditMode || isMobile);

  useKeyboardShortcuts(fabricCanvas);

  const displayInfoPx = useMemo(
    () =>
      displayInfo
        ? {
            ...displayInfo,
            spreadWidth: fabricUtils.mm2px(displayInfo.spreadWidth),
            spreadHeight: fabricUtils.mm2px(displayInfo.spreadHeight),
          }
        : undefined,
    [
      fabricUtils.mm2px,
      displayInfo?.spreadWidth,
      displayInfo?.spreadHeight,
      displayInfo?.spreadIndex,
      displayInfo?.spreadCoords,
      displayInfo?.spotFinishingType,
    ],
  );

  return (
    <div className={cn(styles.EditorArea, 'cy-canvas-wrap')} style={{ backgroundColor }}>
      <div
        ref={canvasDiv}
        className={cn(styles.canvasContainer, {
          [styles.hidden]: dimensions.height === 0,
        })}
      >
        <canvas id={CANVAS_ID} />
        {fabricCanvas && fabricUtils.setup && displayInfo && displayInfoPx && (
          <FabricUtilsContext.Provider value={fabricUtils}>
            <FabricCanvasContext.Provider value={fabricCanvas}>
              {designDimensions && showRuler && (
                <Rulers
                  spreadWidth={displayInfo.spreadWidth}
                  spreadHeight={displayInfo.spreadHeight}
                  spreadCoords={displayInfo.spreadCoords}
                  dimensions={designDimensions}
                />
              )}
              <FileDropZone
                spreadWidth={displayInfo.spreadWidth}
                spreadHeight={displayInfo.spreadHeight}
                spreadCoords={displayInfo.spreadCoords}
              />
              {showEmptyDesignPlaceholder && (
                <EmptyContentPlaceholder
                  coords={displayInfoPx.spreadCoords}
                  spreadIndex={displayInfoPx.spreadIndex}
                  canvasRotation={canvasRotation}
                />
              )}
            </FabricCanvasContext.Provider>
          </FabricUtilsContext.Provider>
        )}
      </div>
      {fabricCanvas && fabricUtils.setup && displayInfo && displayInfoPx && (
        <FabricUtilsContext.Provider value={fabricUtils}>
          <FabricCanvasContext.Provider value={fabricCanvas}>
            {showGrid && (
              <Grid
                spreadCoords={displayInfo.spreadCoords}
                spreadWidth={displayInfo.spreadWidth}
                spreadHeight={displayInfo.spreadHeight}
              />
            )}
            <SpreadDisplayContext.Provider value={displayInfoPx}>
              {spreadsInfo.map((spreadInfo) => (
                <Spread
                  spreadIndex={spreadInfo.spreadIndex}
                  spreadWidth={spreadInfo.spreadWidth}
                  spreadHeight={spreadInfo.spreadHeight}
                  spreadCoords={spreadInfo.spreadCoords}
                  key={spreadInfo.spreadIndex}
                  canvasRotation={canvasRotation}
                  spreadsInfo={spreadsInfo}
                />
              ))}
            </SpreadDisplayContext.Provider>
            <Gestures
              canvasDivRef={canvasDiv}
              canvasDim={dimensions}
              spreadCoords={displayInfo.spreadCoords}
              spreadIndex={displayInfo.spreadIndex}
              spreadWidth={displayInfo.spreadWidth}
              spreadHeight={displayInfo.spreadHeight}
            />
            {showPerspectiveLines && (
              <PerspectiveLines
                spreadWidth={displayInfo.spreadWidth}
                spreadHeight={displayInfo.spreadHeight}
                spreadCoords={displayInfo.spreadCoords}
              />
            )}
            {areSpreadsVisible ? (
              <EditorAreaControls
                spreadIndex={displayInfo.spreadIndex}
                isMobile={isMobile}
                spreadCoords={displayInfo.spreadCoords}
                canvasDim={dimensions}
                spreadWidth={displayInfo.spreadWidth}
                spreadHeight={displayInfo.spreadHeight}
                isFocusedMode={!!isFocusedMode}
              />
            ) : (
              <PageSwitcher />
            )}
            {designDimensions && showRollerStripes && (
              <RollerStripes
                spreadWidth={displayInfo.spreadWidth}
                spreadCoords={displayInfo.spreadCoords}
                zIndex={zIndex.ROLLER_STRIPES}
                dimensions={designDimensions}
              />
            )}
            {showCanvasOperationHelper && <ElementOperationOverlay />}
          </FabricCanvasContext.Provider>
        </FabricUtilsContext.Provider>
      )}
      {!showEmpty && showWallpaperEmptyScreen && displayInfo && (
        <WallpaperEmptyPlaceholder spreadWidth={displayInfo.spreadWidth} spreadHeight={displayInfo.spreadHeight} />
      )}
      {showEmpty && <EmptyPlaceholder />}
      <Loader overlay id={LoaderType.EditorArea} />
      <Alert />
    </div>
  );
}

export default React.memo(EditorArea);
