import React, { useEffect } from 'react';

import TemplatesTabContent from 'editor/src/component/DesktopSidebar/TabContents/TemplatesTabContent';
import TemplatesTab from 'editor/src/component/DesktopSidebar/Tabs/Tab/TemplatesTab';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  active?: boolean;
}

function TemplatesMobileTab({ active }: Props) {
  const menu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true });

  useEffect(() => {
    if (active) {
      menu.open();
    } else {
      menu.close();
    }
  }, [active]);

  return (
    <>
      <TemplatesTab />
      {menu.render(<TemplatesTabContent />)}
    </>
  );
}

export default React.memo(TemplatesMobileTab);
