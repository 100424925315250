import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NoMarginIllustration from './no-margin-illustration.svg';
import WithMarginIllustration from './with-margin-illustration.svg';

import styles from './index.module.scss';

export type AutofillMarginOption = 'margins' | 'no-margins';

export interface Props {
  marginOption: AutofillMarginOption;
  selectOption: (marginOption: AutofillMarginOption) => void;
}

function AutofillOptions({ marginOption, selectOption }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.description}>{t('autofill-options-description')}</div>
      <div className={styles.options}>
        <button
          type="button"
          className={cn(styles.option, { [styles.selected]: marginOption === 'margins' })}
          onClick={() => selectOption('margins')}
        >
          <img src={WithMarginIllustration} alt="WithMarginIllustration" />
          <h3>{t('White margins')}</h3>
          <p>{t('Photos are displayed with consistent white space around them for a clean layout.')}</p>
        </button>
        <button
          type="button"
          className={cn(styles.option, { [styles.selected]: marginOption === 'no-margins' })}
          onClick={() => selectOption('no-margins')}
        >
          <img src={NoMarginIllustration} alt="NoMarginIllustration" />
          <h3>{t('No margins')}</h3>
          <p>{t('Photos are edge-to-edge for a bold, full-bleed look, maximizing the use of space.')}</p>
        </button>
      </div>
    </>
  );
}

export default React.memo(AutofillOptions);
