import React, { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import setMobileViewOperation from 'editor/src/store/app/operation/setMobileViewOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import BottomBarMobile from 'editor/src/component/BottomBarMobile';
import useEcommerceHeader from 'editor/src/component/DesktopLayout/useEcommerceHeader';
import EditorArea from 'editor/src/component/EditorArea';
import Preview from 'editor/src/component/Preview';
import TopMenuMobile from 'editor/src/component/TopMenuMobile';
import MobileMenuContainer from 'editor/src/component/useMobileMenu/MobileMenuContainer';

import LoadingBackdrop from '../LoadingBackdrop';
import MobileHeader from '../MobileHeader';
import MobileTextEditOverlay from '../MobileTextEditOverlay';
import SpreadListView from '../SpreadListView';
import Toggle from '../Toggle';

import getToggleOptions from './getToggleOptions';

import styles from './index.module.scss';

function MobileLayout() {
  const selectedMode = useSelector((state) => state.app.mobileView);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isSpreadListViewEnabled = useSelector((state) => getHostSetting(state, 'enableSpreadsView'));

  const toggleOptions = useMemo(() => {
    return getToggleOptions((mode) => dispatch(setMobileViewOperation(mode)), t);
  }, [selectedMode, t]);

  const handleSwitchToCanvasMode = useCallback(() => {
    dispatch(setMobileViewOperation('canvas'));
  }, []);

  const {
    isEditorHeaderEnabled,
    handleHeaderBackButtonClick,
    handleHeaderContinueButtonClick,
    productPrice,
    isLoading,
  } = useEcommerceHeader();

  return (
    <div className={styles.MobileLayout}>
      <MobileTextEditOverlay />
      {isEditorHeaderEnabled && (
        <MobileHeader
          onBackButtonClick={handleHeaderBackButtonClick}
          onCartButtonClick={handleHeaderContinueButtonClick}
          productPrice={productPrice}
        >
          {isSpreadListViewEnabled && <Toggle options={toggleOptions} selected={selectedMode} />}
        </MobileHeader>
      )}
      {(!isSpreadListViewEnabled || selectedMode !== 'spreads') && <TopMenuMobile />}
      {!isSpreadListViewEnabled && <EditorArea isMobile />}
      {isSpreadListViewEnabled && selectedMode === 'canvas' && <EditorArea isMobile areSpreadsVisible={false} />}
      {isSpreadListViewEnabled && selectedMode === 'spreads' && (
        <SpreadListView onSpreadClick={handleSwitchToCanvasMode} />
      )}

      {(!isSpreadListViewEnabled || selectedMode !== 'spreads') && <BottomBarMobile />}
      <Preview />
      <MobileMenuContainer />
      {isLoading && createPortal(<LoadingBackdrop />, document.body)}
    </div>
  );
}

export default React.memo(MobileLayout);
