import { RootState } from 'editor/src/store/index';

import { MediaText } from '../types';

import getSelectedMediaElementsObjects from './getSelectedMediaElementsObjects';

const getSelectedTextElementObject = (state: RootState) =>
  getSelectedMediaElementsObjects(state).find((element) => element?.type === 'text') as MediaText | undefined;

export default getSelectedTextElementObject;
