import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import applyAutoFill from 'editor/src/util/autofill/applyAutoFill';

import { AutofillMarginOption } from 'editor/src/component/Autofill/AutofillOptions';

import applyAutoFillOperation from './applyAutoFillOperation';
import applyDynamicLayout from './autofill/applyDynamicLayout';

export interface AutoFillData {
  pageIndex: number;
  spreadIndex: number;
  layoutSchema: LayoutSchema;
  images?: GalleryImage[];
  replacePlaceholderElements?: boolean;
}

export const PHOTOBOOK_LAYOUT_MARGIN = 10;

const autoFillOperation =
  (marginOption?: AutofillMarginOption) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const designData = getDesignData(state);
    const { selectedImagesIds } = state.gallery;

    const galleryImages =
      selectedImagesIds.length > 1
        ? getGalleryImages(state).filter((image) => selectedImagesIds.includes(image.id))
        : getGalleryImages(state);
    if (!designData || !galleryImages) {
      return;
    }

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Auto fill'));
      const { useDynamicLayout } = state.hostSettings;
      if (useDynamicLayout) {
        dispatch(
          applyDynamicLayout(
            designData,
            galleryImages,
            useDynamicLayout.spreadIndexesToIgnore,
            marginOption === 'margins' ? PHOTOBOOK_LAYOUT_MARGIN : 0,
          ),
        );
      } else {
        const autoFillData = applyAutoFill(designData, galleryImages, state);
        dispatch(applyAutoFillOperation(autoFillData));
      }
    });
  };

export default autoFillOperation;
