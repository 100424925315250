import React, { useCallback } from 'react';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ptRound from 'editor/src/util/ptRound';

import ControlButton from 'editor/src/component/ControlButton';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import FontSizeActionBarMobile from '../../ActionBarSection/FontSizeActionBarMobile/FontSizeActionBarMobile';

function ButtonFontSize() {
  const currentFontSize = useSelector((store) => getCurrentFontStylesProperty(store, 'fontSize'));
  const isActive = useSelector((store) => isSidebarTabActive(store, TAB_NAMES.FONT_SIZE));
  const dispatch = useDispatch();
  const mobileMenu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true });
  const isMobile = useIsMobile();

  const handleClick = () => {
    if (isMobile) {
      mobileMenu.open();
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_SIZE));
    } else if (isActive) {
      dispatch(setSidebarActiveTabOperation(-1));
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_SIZE));
    }
  };

  const selectedElements = useSelector(getSelectedMediaElementsObjects);
  const firstElement = selectedElements[0];

  const closeMenu = useCallback(() => {
    mobileMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <ControlButton onClick={handleClick} on={isActive}>
        <span>{ptRound(currentFontSize)}</span>
      </ControlButton>
      {firstElement?.type === 'text' &&
        mobileMenu.render(<FontSizeActionBarMobile closeMenuCb={closeMenu} element={firstElement} />)}
    </>
  );
}

export default ButtonFontSize;
