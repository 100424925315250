import cn from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import removeMediaElementOperation from 'editor/src/store/design/operation/removeMediaElementOperation';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getSelectedTextElementObject from 'editor/src/store/design/selector/getSelectedTextElementObject';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import updateTextPropertiesWithoutRender from 'editor/src/store/design/util/updateTextPropertiesWithoutRender';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import { useSelector, useDispatch, useStore } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';
import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import ControlButton from '../ControlButton';
import IconCross from '../Icon/IconCross';

import styles from './index.module.scss';

/**
 * the trick with this component is that the textarea must always exist so that we can synchronously call focus() on it
 * this will open the keyboard on safari
 */

const TEXT_EDIT_OVERLAY_INPUT_ID = 'cy-mobile-text-edit-overlay-input';

export function focusTextEditOverlayInput() {
  const textArea = document.getElementById(TEXT_EDIT_OVERLAY_INPUT_ID) as HTMLTextAreaElement | null;
  if (textArea) {
    textArea.focus();
    textArea.select();
  }
}

function MobileTextEditOverlay() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isTextEditMode = useSelector((state) => state.editor.textEditMode);
  const textElement = useSelector(getSelectedTextElementObject);
  const store = useStore();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { liveUpdate } = useMediaElementLiveUpdates(textElement);

  const handleClose = useCallback(() => {
    dispatch(setTextEditModeOperation(false));
    inputRef.current?.blur();
  }, []);

  // Handle escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleClose]);

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!textElement) {
      return;
    }

    const elementUpdate = { extra: { ...textElement.extra, text: e.target.value } };
    updateTextPropertiesWithoutRender(textElement, elementUpdate, undefined);
    liveUpdate({ ...textElement, ...elementUpdate });
  };

  const onExit = () => {
    handleClose();
    if (!textElement || !inputRef.current) {
      return;
    }

    const newValue = inputRef.current.value;
    if (newValue === textElement.extra.text) {
      return;
    }

    const address = getStructureIndexByElementUuid(store.getState().design.designData, textElement.uuid);
    if (address) {
      const textEligibleForRemove = !newValue || !newValue.trim().length;
      if (textEligibleForRemove) {
        dispatch(removeMediaElementOperation(address));
      } else {
        const elementUpdate = {
          extra: { ...textElement.extra, text: newValue },
        };
        updateTextPropertiesWithoutRender(textElement, elementUpdate, undefined);
        dispatch(updateMediaElementOperation(address, elementUpdate, MediaUpdateActionName.TEXT_UPDATED, true));
      }
    }
  };

  useEffect(() => {
    if (!isTextEditMode) {
      return undefined;
    }
    inputRef.current?.focus();

    const timeout = window.setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, 100);
    return () => clearTimeout(timeout);
  }, [isTextEditMode]);

  useOnClickOutside(containerRef, onExit, !isTextEditMode);

  return (
    <div
      className={cn(
        styles.MobileTextEditOverlay,
        {
          [styles.visible]: isTextEditMode,
        },
        'cy-mobile-text-edit-overlay',
      )}
      ref={containerRef}
    >
      <textarea
        className={styles.input}
        placeholder={t('Enter text')}
        defaultValue={textElement?.extra.text}
        onChange={onTextChange}
        ref={inputRef}
        rows={1}
        id={TEXT_EDIT_OVERLAY_INPUT_ID}
      />
      <ControlButton className={styles.closeBtn} onClick={onExit}>
        <IconCross />
      </ControlButton>
    </div>
  );
}

export default React.memo(MobileTextEditOverlay);
