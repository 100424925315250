import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import autoFillOperation from 'editor/src/store/editor/operation/autoFillOperation';
import { useDispatch } from 'editor/src/store/hooks';

import IconPhotobook from 'editor/src/component/Autofill/icon-photobook.svg';
import Button from 'editor/src/component/Button';
import AbstractPopup, { PopupType } from 'editor/src/component/Popup/AbstractPopup';
import Section from 'editor/src/component/Popup/AbstractPopup/Section';
import { PopupName } from 'editor/src/component/Popup/popups';

import AutofillOptions, { AutofillMarginOption } from '../../Autofill/AutofillOptions';

import styles from './index.module.scss';

function AutoFillPopup() {
  const [selectedOption, setSelectedOption] = useState<AutofillMarginOption>('margins');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCancel = () => {
    dispatch(closePopupOperation());
  };

  const handleConfirm = () => {
    dispatch(autoFillOperation(selectedOption));
    dispatch(closePopupOperation());
  };

  return (
    <AbstractPopup
      onClickOutside={handleCancel}
      name={PopupName.AUTO_FILL_POPUP}
      type={PopupType.INFO}
      hideTitleIcon
      title={
        <>
          <img src={IconPhotobook} alt="IconPhotobook" className="mr-2" />
          {t('Autofill the book with your images')}
        </>
      }
      className={cn(styles.autoFillPopup, 'cy-popup-auto-fill')}
      buttons={
        <>
          <Button secondary onClick={handleCancel} className="cy-popup-option-cancel">
            {t('Cancel')}
          </Button>
          <Button primary onClick={handleConfirm} className="cy-popup-option-continue">
            {t('Continue')}
          </Button>
        </>
      }
    >
      <Section>
        <AutofillOptions marginOption={selectedOption} selectOption={setSelectedOption} />
      </Section>
    </AbstractPopup>
  );
}

export default AutoFillPopup;
