import React from 'react';

import { MenuItem } from 'editor/src/component/Menu/type';
import useFilteredMenuItems from 'editor/src/component/Menu/useFilteredMenuItems';
import Button3dPreview, {
  canShow as preview3dCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/Button3dPreview';
import ButtonFlatPreview, {
  canShow as flatCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonFlatPreview';
import ButtonFoilLayers, {
  canShow as foilLayersCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonFoilLayers';
import ButtonWarnings, { canShow as warningsCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonWarnings';
import ButtonMenuMore from 'editor/src/component/TopMenuMobile/buttons/ButtonMenuMore';

import styles from './index.module.scss';

const MENU_ITEMS: MenuItem[] = [
  { Component: Button3dPreview, canShow: preview3dCanShow },
  { Component: ButtonFlatPreview, canShow: flatCanShow, mode: 'label' },
  { Component: ButtonWarnings, canShow: warningsCanShow, mode: 'icon' },
  { Component: ButtonFoilLayers, canShow: foilLayersCanShow, mode: 'icon' },
];

function RightSectionMobile() {
  const { items, menuContext } = useFilteredMenuItems(MENU_ITEMS);

  return (
    <div className={styles.rightSectionMobile}>
      {items.map(({ Component, mode }, i) => (
        <Component key={i} isMobile mode={mode || 'icon'} context={menuContext} />
      ))}
      <ButtonMenuMore />
    </div>
  );
}

export default React.memo(RightSectionMobile);
