import React, { useCallback } from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import PersonalizationMobileMenu from 'editor/src/component/BottomBarMobile/ActionBarSection/PersonalizationMobileMenu';
import IconPersonsalisation from 'editor/src/component/Icon/IconPersonsalisation';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';
import useMobileMenu from 'editor/src/component/useMobileMenu';

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonPersonalization({ mode, close, isMobile }: Props) {
  const isActive = useSelector((state) =>
    isMobile
      ? isSidebarTabActive(state, TAB_NAMES.PERSONALIZATION)
      : isTopMenuDropdownVisible(state, TopMenuDropdown.Personalization),
  );
  const dispatch = useDispatch();
  const personalizationMenu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true });

  const handleClick = () => {
    if (isMobile) {
      personalizationMenu.open();
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PERSONALIZATION));
    } else {
      dispatch(setActiveTopMenuDropdownOperation(TopMenuDropdown.Personalization));
      close?.();
    }
  };

  const closeMenu = useCallback(() => {
    personalizationMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <MenuButton
        Icon={IconPersonsalisation}
        on={isActive}
        mode={mode}
        handleClick={handleClick}
        i18nKey="editor-personalization"
        className="cy-button-personalization"
      />
      {personalizationMenu.render(<PersonalizationMobileMenu closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(ButtonPersonalization);
