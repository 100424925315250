import React from 'react';

import { CanShow } from 'editor/src/component/Menu/type';

import SwitchTemplateButton from './SwitchTemplateButton';

import styles from './index.module.scss';

export const canShow: CanShow = (state) => {
  return state.hostSettings.enableSwitchDesignTemplates;
};

function ButtonSwitchTemplate() {
  return (
    <div className={styles.switchTemplateButtonWrapper}>
      <SwitchTemplateButton className={styles.button} />
    </div>
  );
}

export default React.memo(ButtonSwitchTemplate);
