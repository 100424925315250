import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { SpreadDesignTemplate } from 'editor/src/store/editorModules/templates/types';
import designStructureToSpreadStructure from 'editor/src/store/editorModules/templates/utils/designStructureToSpreadStructure';
import { useStore } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import useCustomToastr from 'editor/src/component/CustomToastrComponent/useCustomToastr';
import IconPlus from 'editor/src/component/Icon/IconPlus';

import styles from './index.module.scss';

const DESIGN_TEMPLATE_DEFAULT_NAME = 'My design';

interface Props {
  className?: string;
  onClickCallback?: () => void;
}

function SaveTemplateButton({ className, onClickCallback }: Props) {
  const { t } = useTranslation();
  const store = useStore();
  const showToastr = useCustomToastr();

  const onSaveTemplate = useCallback(() => {
    const state = store.getState();
    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const currentDesignData = getDesignData(state);

    if (!currentDesignData || !currentDesignData?.spreads?.[currentSpreadIndex].pages[0].groups.media?.length) {
      showToastr('info', t('No media has been applied to the design'));
      return;
    }

    const spreadDesignStructure = designStructureToSpreadStructure(currentDesignData, currentSpreadIndex);
    const templateExists = state.editorModules.templates.designTemplates.some((template: SpreadDesignTemplate) => {
      return JSON.stringify(spreadDesignStructure) === JSON.stringify(template.structure);
    });

    // if template already exists we should not duplicate it
    if (templateExists) {
      showToastr('info', t('The current design already exists'));
      return;
    }
    sendPostMessage('design.saveDesignTemplate', {
      name: DESIGN_TEMPLATE_DEFAULT_NAME,
      structure: spreadDesignStructure,
      hasPersonalization: currentDesignData?.plugins?.includes(PluginName.personalizationStudio) || false,
    });

    onClickCallback?.();
  }, []);

  return (
    <Button secondary stopPropagation onClick={onSaveTemplate} className={cn(className, 'cy-button-add-template')}>
      <IconPlus className={styles.icon} />
      {t('Save design')}
    </Button>
  );
}

export default React.memo(SaveTemplateButton);
