import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Coords, ElementSize, Page } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import { CanvasRotation, VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';
import useEmptyPlaceholderEventHandlers from 'editor/src/component/EditorArea/useEmptyPlaceholderEventHandlers';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import { markDesignDataAsTouchedAction as markDesignDataAsTouchedOperation } from '../../../store/design/slice';
import { useIsMobile } from '../../useDetectDeviceType';
import useCanvasRotation from '../useCanvasRotation';

import EmptyPlaceholderIcon from './EmptyPlaceholderIcon';

import styles from './index.module.scss';

interface Props {
  spreadCoords: Coords;
  content: ElementSize;
  canvasRotation: CanvasRotation;
  page: Page;
}

const PADDING = 20;

function EmptyContentPlaceholder({ content, spreadCoords, canvasRotation, page }: Props) {
  const placeholderContainerRef = useRef<HTMLDivElement>(null);
  const isDragging = useSelector((state) => state.editor.dragState !== undefined);
  const { mm2px } = useFabricUtils();
  const isMobile = useIsMobile();

  const fabricCanvas = useFabricCanvas();
  const uploader = useContext(UploaderContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (page?.groups.media?.length) {
      dispatch(markDesignDataAsTouchedOperation());
    }
  }, [page?.groups.media?.length]);

  const contentWidth = mm2px(content.width);
  const contentHeight = mm2px(content.height);
  const contentX = spreadCoords.left + mm2px((page?.x ?? 0) + content.x + content.width / 2);
  const contentY = spreadCoords.top + mm2px((page?.y ?? 0) + content.y + content.height / 2);
  const rotatedPoint = useCanvasRotation(canvasRotation, contentX, contentY);

  const width = contentWidth - PADDING * 2;
  const height = contentHeight - PADDING * 2;
  const placeholderSize = Math.min(width, height);
  const horizontalPadding = useMemo(() => placeholderSize * 0.1, [placeholderSize]);

  const getPosition = useCallback(() => {
    const offsetX = fabricCanvas.viewportTransform ? fabricCanvas?.viewportTransform[4] : 0;
    const offsetY = fabricCanvas.viewportTransform ? fabricCanvas?.viewportTransform[5] : 0;

    const zoom = fabricCanvas.getZoom();
    // TODO with the canvas rotation offset
    const left = (rotatedPoint.x - placeholderSize / 2) * zoom + offsetX;
    const top = (rotatedPoint.y - placeholderSize / 2) * zoom + offsetY;
    return { top, left, zoom };
  }, [rotatedPoint, contentWidth, contentHeight, placeholderSize, canvasRotation]);

  useEffect(() => {
    const onViewportChange = () => {
      if (!placeholderContainerRef.current) {
        return;
      }
      const { top, left, zoom } = getPosition();
      placeholderContainerRef.current.style.transform = `translate3d(${left}px,${top}px,0) scale(${zoom})`;
    };

    fabricCanvas.on(VIEWPORT_CHANGED_EVENT, onViewportChange);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT, onViewportChange);
    };
  }, [getPosition]);

  const { onPlaceholderClick } = useEmptyPlaceholderEventHandlers(uploader);

  const position = getPosition();
  const { galleryTab } = useEditorModeTranslations();

  return (
    <div
      className={styles.placeholderContainer}
      ref={placeholderContainerRef}
      style={{
        width: `${placeholderSize}px`,
        height: `${placeholderSize}px`,
        padding: `0 ${horizontalPadding}px`,
        transform: `translate3d(${position.left}px,${position.top}px,0) scale(${position.zoom})`,
        pointerEvents: isDragging ? 'none' : 'auto',
      }}
      onClick={() => onPlaceholderClick(true)}
    >
      <EmptyPlaceholderIcon className={styles.icon} />
      <span className={styles.title} style={{ fontSize: placeholderSize / 13 }}>
        {t(isMobile ? 'Tap to add your design here' : 'Drop your design here')}
      </span>
      <span className={styles.subtitle} style={{ fontSize: placeholderSize / 20 }}>
        {isMobile
          ? t('or-dnd-from-files', { files: galleryTab })
          : t('or-use-add-new-button', { 'add-new': t('editor-add-layer') })}
      </span>
    </div>
  );
}

export default React.memo(EmptyContentPlaceholder);
