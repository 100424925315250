import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';

import setDesignDataOperation from 'editor/src/store/design/operation/setDesignDataOperation';
import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { DesignData } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getTemplates from 'editor/src/store/editorModules/templates/selector/getTemplates';
import { Thunk } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';
import reflectSpreadAndApply from 'editor/src/util/reflectDesignData/reflectSpreadAndApply';

export interface RemoveDesignTemplatesPayload {
  id: string;
  success: boolean;
  error?: string;
}

const applyDesignTemplateByIdOperation =
  (templateId: string, onApplyCallback?: (design: DesignData) => void): Thunk =>
  (dispatch, getState: () => RootState) => {
    const state = getState();
    const template = getTemplates(state).find((template) => template.id === templateId);
    if (!template) {
      const error = new Error(`Can not find template by id ${templateId}`);
      captureException(error);
      throw error;
    }

    const destDesign = getDesignData(state);

    if (!destDesign) {
      return;
    }

    const newDesignData: DesignData = cloneDeep(destDesign);

    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const destSpread = newDesignData?.spreads[currentSpreadIndex];
    const sourceDesign = template.structure;
    const sourceSpread = sourceDesign.spreads[0];

    if (!sourceSpread || !destDesign || !destSpread) {
      return;
    }

    reflectSpreadAndApply({
      sourceSpread,
      destSpread,
      sourceDesign,
      newDesign: newDesignData,
      sourceSpreadIndex: 0,
      context: getReflectContext(state),
      mode: 'adapt',
    });

    void dispatch(setDesignDataOperation(newDesignData, true, onApplyCallback));
  };

export default applyDesignTemplateByIdOperation;
