import React, { useContext, useEffect } from 'react';

import isAnyImageSelected from 'editor/src/store/design/selector/isAnyImageSelected';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getGalleryImagesCount from 'editor/src/store/gallery/selector/getGalleryImagesCount';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';

import GalleryMobileMenu from 'editor/src/component/BottomBarMobile/ActionBarSection/GalleryMobileMenu';
import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconGallery from 'editor/src/component/Icon/IconGallery';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active: boolean;
}

function MobileGalleryTab({ pluginName, customTabInfo, active }: Props) {
  const dispatch = useDispatch();
  const anyImageSelected = useSelector(isAnyImageSelected);
  const galleryImagesCount = useSelector(getGalleryImagesCount);
  const activeTab = useSelector(getSidebarActiveTab);
  const isDraggingImage = useSelector((state) => state.editor.dragState === 'local');
  const topMenu = useMobileMenu();
  const galleryMenu = useMobileMenu({ addBackdrop: false });
  const uploader = useContext(UploaderContext);
  const { galleryTab } = useEditorModeTranslations();

  useEffect(() => {
    galleryMenu.hide(isDraggingImage);
  }, [isDraggingImage]);

  useEffect(() => {
    if (activeTab === TAB_NAMES.GALLERY) {
      galleryMenu.open();
    }
  }, [activeTab]);

  const closeGalleryMenu = () => {
    galleryMenu.close();
  };

  const onMobileClick = (active: boolean) => {
    galleryMenu.open();
    if (!active) {
      if (!anyImageSelected) {
        dispatch(removeAllSelectedMediaElementsOperation());
      }

      if (galleryImagesCount === 0) {
        uploader.open();
      }
    }
    topMenu.close();
  };

  return (
    <>
      <Tab
        key={TAB_NAMES.GALLERY}
        name={TAB_NAMES.GALLERY}
        title={galleryTab}
        icon={<IconGallery active={active} />}
        pluginName={pluginName}
        onMobileClick={onMobileClick}
        customTabInfo={customTabInfo}
      />
      {galleryMenu.render(<GalleryMobileMenu closeGalleryMenuCb={closeGalleryMenu} />)}
    </>
  );
}

export default React.memo(MobileGalleryTab);
