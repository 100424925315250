import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconVariants from 'editor/src/component/Icon/IconVariants';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import VariantsTabContent from '../../../TabContents/VariantsTabContent';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active: boolean;
}

function VariantsTab({ pluginName, customTabInfo, active }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const dispatch = useDispatch();
  const topMenu = useMobileMenu();
  const variantsMenu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true });

  const onMobileClick = () => {
    if (active) {
      dispatch(setSidebarActiveTabOperation(-1));
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.VARIANTS));
    }
    topMenu.close();
  };

  useEffect(() => {
    if (active) {
      variantsMenu.open();
    } else {
      variantsMenu.close();
    }
  }, [active]);

  const closeMenu = useCallback(() => {
    variantsMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  if (!isMobile) {
    return null;
  }

  return (
    <>
      <Tab
        key={TAB_NAMES.VARIANTS}
        name={TAB_NAMES.VARIANTS}
        title={t('editor-variants')}
        icon={<IconVariants />}
        pluginName={pluginName}
        customTabInfo={customTabInfo}
        onMobileClick={onMobileClick}
      />
      {variantsMenu.render(<VariantsTabContent closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(VariantsTab);
