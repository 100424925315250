import React, { useCallback, useEffect } from 'react';

import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { CustomTabInfo } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useMobileMenu from 'editor/src/component/useMobileMenu';

import AddonsTabContent from '../../../TabContents/AddonsTabContent';
import AddonsTab from '../AddonsTab';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active?: boolean;
}

function AddonsMobileTab({ pluginName, customTabInfo, active }: Props) {
  const menu = useMobileMenu({ addBackdrop: false });
  const dispatch = useDispatch();
  const isDraggingImage = useSelector((state) => state.editor.dragState === 'local');

  useEffect(() => {
    menu.hide(isDraggingImage);
  }, [isDraggingImage]);

  useEffect(() => {
    if (active) {
      menu.open();
    } else {
      menu.close();
    }
  }, [active]);

  const closeMenu = useCallback(() => {
    menu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <AddonsTab pluginName={pluginName} customTabInfo={customTabInfo} />
      {menu.render(<AddonsTabContent title={customTabInfo?.title} closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(AddonsMobileTab);
