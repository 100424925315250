import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import useBlobAsUrl from 'editor/src/util/useBlobAsUrl';

import styles from './index.module.scss';

export interface PreviewItem {
  url?: string;
  blob?: Blob | null;
  name: string;
  thumbnailUrl?: string;
  spreadIndex?: number;
}

interface Props {
  item: PreviewItem;
  active: boolean;
  index: number;
  onSelect: (index: number) => void;
}

function ListItem({ item, active, index, onSelect }: Props) {
  const [loaded, setLoaded] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const blobUrl = useBlobAsUrl(item.blob || null);
  const imageUrl = item.thumbnailUrl || item.url || blobUrl;

  const handleImageLoad = () => {
    setLoaded(true);
  };

  function onClick() {
    onSelect(index);
  }

  useEffect(() => {
    if (active) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [active]);

  return (
    <div
      className={cn(styles.item, 'cy-list-item', {
        [styles.active]: active,
        'cy-active': active,
        [styles.loaded]: loaded,
      })}
      onClick={onClick}
      ref={divRef}
    >
      <div
        className={cn(styles.image, {
          [styles.loaded]: loaded,
          'cy-loaded': loaded,
        })}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt={item.name}
            draggable={false}
            onLoad={handleImageLoad}
            style={{ display: loaded ? 'block' : 'none' }}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(ListItem);
