import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconPhotobook from 'editor/src/component/Autofill/icon-photobook.svg';

import AutofillOptions, { AutofillMarginOption } from '../../../Autofill/AutofillOptions';
import Button from '../../../Button';
import DrawerHeader from '../../DrawerHeader';

import styles from './index.module.scss';

interface Props {
  onCancel: () => void;
  onContinue: (option: AutofillMarginOption) => void;
}
function AutofillMobileMenu({ onCancel, onContinue }: Props) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<AutofillMarginOption>('margins');

  return (
    <div className={cn(styles.AutofillMobileMenu, 'cy-auto-fill-menu')}>
      <DrawerHeader>
        <>
          <img src={IconPhotobook} alt="IconPhotobook" className="mr-2" />
          <h1 className={styles.title}>{t('Autofill the book with your images')}</h1>
        </>
      </DrawerHeader>
      <div className={styles.content}>
        <AutofillOptions marginOption={selectedOption} selectOption={setSelectedOption} />
      </div>
      <div className={styles.buttons}>
        <Button primary stopPropagation onClick={() => onContinue(selectedOption)}>
          {t('Continue')}
        </Button>
        <Button secondary stopPropagation onClick={onCancel}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  );
}

export default React.memo(AutofillMobileMenu);
