import React from 'react';

import { Coords } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import { CanvasRotation } from '../types';

import EmptyPagePlaceholder from './EmptyPagePlaceholder';

interface Props {
  coords: Coords;
  spreadIndex: number;
  canvasRotation: CanvasRotation;
}

function EmptyContentPlaceholderWrapper({ spreadIndex, coords, canvasRotation }: Props) {
  const currentSpread = useSelector((state) => state.design.designData?.spreads[spreadIndex]);
  const focusedContentAddress = useSelector((state) => state.editor.focusedContentAddress);

  if (!currentSpread) {
    return null;
  }

  return (
    <>
      {currentSpread.pages.map((page) => (
        <EmptyPagePlaceholder
          key={page.page_nr}
          page={page}
          spread={currentSpread}
          coords={coords}
          canvasRotation={canvasRotation}
          contentAddress={focusedContentAddress}
        />
      ))}
    </>
  );
}

export default React.memo(EmptyContentPlaceholderWrapper);
