import React from 'react';
import { useTranslation } from 'react-i18next';

import addNewTextToPageOperation from 'editor/src/store/design/operation/addNewTextToPageOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconText from 'editor/src/component/Icon/IconText';
import { focusTextEditOverlayInput } from 'editor/src/component/MobileTextEditOverlay';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function TextsTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const topMenu = useMobileMenu();

  const onMobileClick = () => {
    topMenu.close();
    dispatch(addNewTextToPageOperation(spreadIndex, 0));
    focusTextEditOverlayInput(); // is it important to focus the input synchronously on the click for safari
  };

  return (
    <Tab
      key={TAB_NAMES.TEXTS}
      name={TAB_NAMES.TEXTS}
      title={t('editor-text')}
      icon={<IconText />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
      onMobileClick={onMobileClick}
    />
  );
}

export default React.memo(TextsTab);
