import cn from 'classnames';
import React from 'react';

import { SpreadDesignTemplate } from 'editor/src/store/editorModules/templates/types';

import useSpreadTemplatePreview from 'editor/src/util/design/useSpreadTemplatePreview';

import IconBin from 'editor/src/component/Icon/IconBin';
import { RequestRenderFn } from 'editor/src/component/SpreadPreview';
import { SpreadPreviewOptions } from 'editor/src/component/SpreadPreview/createSpreadPreview';

import styles from './index.module.scss';

interface Props {
  template: SpreadDesignTemplate;
  name: string;
  requestRender: RequestRenderFn;
  onDelete: (id: string, name: string) => void;
  onApply: (template: SpreadDesignTemplate) => void;
}

const PREVIEW_DIMENSION = 89;

function TemplateItem({ template, name, requestRender, onDelete, onApply }: Props) {
  const previewOptions: SpreadPreviewOptions = {
    showBlanks: true,
    showEmptyImages: true,
  };
  const { id, structure, previewUrl } = template;

  const { preview } = previewUrl
    ? { preview: { dataURL: previewUrl } }
    : useSpreadTemplatePreview(
        requestRender,
        structure.spreads[0],
        id,
        0,
        { dimension: 'both', value: PREVIEW_DIMENSION },
        previewOptions,
        true,
      );

  const onDeleteClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDelete(id, name);
  };

  return (
    <div className={cn(styles.wrapper, 'cy-design-template-item')}>
      <div
        className={styles.preview}
        onClick={() => onApply(template)}
        style={{
          backgroundImage: preview ? `url(${preview.dataURL})` : undefined,
        }}
      />
      <div className={styles.title}>{name}</div>
      <div className={cn(styles.removeButton, 'cy-template-remove-btn')} onClick={onDeleteClicked}>
        <IconBin className={styles.removeIcon} />
      </div>
    </div>
  );
}

export default React.memo(TemplateItem);
