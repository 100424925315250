import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { DesignData, MediaImage } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import applyDesignTemplateByIdOperation from 'editor/src/store/editorModules/templates/operation/applyDesignTemplateByIdOperation';
import getTemplates from 'editor/src/store/editorModules/templates/selector/getTemplates';
import { SpreadDesignTemplate } from 'editor/src/store/editorModules/templates/types';
import { useSelector, useDispatch, useStore } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import { LAYOUT_TAG_GRID } from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import useCustomToastr from 'editor/src/component/CustomToastrComponent/useCustomToastr';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import { PopupName } from 'editor/src/component/Popup/popups';
import { useCanvasRendering } from 'editor/src/component/SpreadPreview';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import EmptyTemplatesPage from './EmptyTemplatesPage';
import TemplateItem from './TemplateItem';

import styles from './index.module.scss';

function TemplatesTabContent() {
  const { t } = useTranslation();
  const templates = useSelector(getTemplates);
  const images = useSelector((state) => state.gallery.images);
  const dispatch = useDispatch();
  const { requestRender } = useCanvasRendering(templates?.length > 0);
  const store = useStore();
  const isMobile = useIsMobile();

  const showToastr = useCustomToastr();

  const onDelete = useCallback((id: string) => {
    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('Delete design template'),
        textLines: [t('Are you sure you want to delete the design template?')],
        options: [
          {
            title: t('editor-cancel'),
          },
          {
            title: t('Delete'),
            onConfirm: () => {
              sendPostMessage('design.deleteDesignTemplate', { id });
            },
          },
        ],
        hideTitleIcon: true,
      }),
    );
  }, []);

  const onApply = useCallback((template: SpreadDesignTemplate) => {
    const state = store.getState();
    const currentSpread = getSpread(state, getCurrentSpreadIndex(state));

    if (currentSpread?.tags?.includes(LAYOUT_TAG_GRID)) {
      showToastr('info', t('Cannot apply the design to the current page'));
      return;
    }
    // check if all assets are presented in the list
    const mediaElements = (template.structure.spreads[0].pages[0].groups.media || []) as MediaImage[];
    const isAssetMissing = mediaElements.some(({ imageId }) => imageId && !images.find(({ id }) => id === imageId));
    isAssetMissing && sendPostMessage('assets.getTemplateImages', { templateId: template.id });
    const onApplyCallback = (newDesignData: DesignData) => {
      sendPostMessage('log.designTemplateApplied', {
        productUid: newDesignData?.product_uid || '',
        structure: template.structure,
        hasPersonalization: newDesignData?.plugins?.includes(PluginName.personalizationStudio) || false,
      });
    };
    dispatch(applyDesignTemplateByIdOperation(template.id, onApplyCallback));
  }, []);

  if (!templates?.length) {
    return <EmptyTemplatesPage />;
  }

  return (
    <div data-ignore-outside-click>
      <TabContentHeader
        title={t('Templates')}
        className={cn({
          [styles.tabHeader]: isMobile,
        })}
      />
      <ContentBlock scroll className={cn({ [styles.tabContent]: isMobile })}>
        <div className={styles.wrapper}>
          {templates.map((template, index) => (
            <TemplateItem
              key={template.id}
              // TODO support renaming
              name={`${template.name} ${index + 1}`}
              requestRender={requestRender}
              template={template}
              onDelete={onDelete}
              onApply={onApply}
            />
          ))}
        </div>
      </ContentBlock>
    </div>
  );
}

export default React.memo(TemplatesTabContent);
