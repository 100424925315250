import React from 'react';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useBrowserColor from 'editor/src/util/useBrowserColor';

import IconFontColor from 'editor/src/component/Icon/IconFontColor';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import FontColorsTabContent from '../../DesktopSidebar/TabContents/FontColorsTabContent';
import useMobileMenu from '../../useMobileMenu';

import MenuButton, { Props as MenuProps } from './MenuButton';

const MenuColorButton = MenuButton as React.FC<MenuProps<{ color: string }>>;

export const canShow: CanShow = (state, { isPersonalizationLocked }) => !isPersonalizationLocked;

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonFontColor({ mode, close, isMobile }: Props) {
  const currentColor = useSelector((state) => getCurrentFontStylesProperty(state, 'color'));
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.FONT_COLORS));
  const dispatch = useDispatch();
  const colorMenu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true, flexLayout: true });
  const color = useBrowserColor(currentColor);

  const handleClick = () => {
    if (isMobile) {
      colorMenu.toggle();
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_COLORS));
      close?.();
    }
  };

  return (
    <>
      <MenuColorButton
        Icon={IconFontColor}
        iconProps={{ color }}
        on={isActive}
        mode={mode}
        handleClick={handleClick}
        i18nKey="editor-font-color"
        className="cy-button-font-color"
      />
      {colorMenu.render(<FontColorsTabContent closeMenuCb={colorMenu.close} />)}
    </>
  );
}

export default React.memo(ButtonFontColor);
