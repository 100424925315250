import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconReplace from 'editor/src/component/Icon/IconReplace';
import { CanShow } from 'editor/src/component/Menu/type';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import GalleryMobileMenu from '../../ActionBarSection/GalleryMobileMenu';

import styles from './index.module.scss';

export const canShow: CanShow = (_, { selectedElementType, isPersonalizationLocked }) =>
  !isPersonalizationLocked && selectedElementType === 'image';

function ButtonReplace() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isActive = useSelector((store) => isSidebarTabActive(store, TAB_NAMES.REPLACE_IMAGE));
  const galleryMenu = useMobileMenu({ addBackdrop: false });

  const closeGalleryMenu = () => {
    galleryMenu.close();
  };

  const handleClick = () => {
    if (isActive) {
      dispatch(setSidebarActiveTabOperation(-1));
      closeGalleryMenu();
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.REPLACE_IMAGE));
      galleryMenu.open();
    }
  };

  return (
    <>
      <ControlButton className={cn('cy-button-replace', styles.container)} on={isActive} onClick={handleClick}>
        <IconReplace className={styles.icon} />
        <span className={styles.label}>{t('editor-replace')}</span>
      </ControlButton>
      {galleryMenu.render(<GalleryMobileMenu closeGalleryMenuCb={closeGalleryMenu} />)}
    </>
  );
}

export default ButtonReplace;
