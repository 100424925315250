import React from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getTextEditMode from 'editor/src/store/editor/selector/getTextEditMode';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconEdit from 'editor/src/component/Icon/IconEdit';
import { focusTextEditOverlayInput } from 'editor/src/component/MobileTextEditOverlay';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

function ButtonEnterEditTextMode() {
  const textEditMode = useSelector(getTextEditMode);
  const dispatch = useDispatch();

  const handleClick = () => {
    batch(() => {
      dispatch(setSidebarActiveTabOperation(-1));
      dispatch(setTextEditModeOperation(!textEditMode));
    });
    focusTextEditOverlayInput(); // is it important to focus the input synchronously on the click for safari
  };

  return (
    <MenuButton
      Icon={IconEdit}
      on={textEditMode}
      mode="label-icon-vertical"
      handleClick={handleClick}
      i18nKey="Edit text"
      className="cy-button-edit-text"
    />
  );
}

export default React.memo(ButtonEnterEditTextMode);
