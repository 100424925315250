import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';

import IconPersonsalisation from '../../../../Icon/IconPersonsalisation';
import useMobileMenu from '../../../../useMobileMenu';
import PersonalizationTabContent from '../../../TabContents/PersonalizationTabContent';

interface Props {
  isActive: boolean;
}

function MobilePersonalizeTab({ isActive }: Props) {
  const menu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true });
  const { t } = useTranslation();
  const closeMenu = () => {
    menu.close();
  };

  const onMobileClick = () => {
    menu.open();
  };

  return (
    <>
      <Tab
        key={TAB_NAMES.PERSONALIZE}
        name={TAB_NAMES.PERSONALIZE}
        title={t('Personalize')}
        icon={<IconPersonsalisation active={isActive} />}
        onMobileClick={onMobileClick}
      />
      {menu.render(<PersonalizationTabContent closeMenuCb={closeMenu} />)}
    </>
  );
}
export default React.memo(MobilePersonalizeTab);
