import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import getLocaleList from 'editor/src/util/getLocaleList';
import { browserLocaleToEditorLocale } from 'editor/src/util/locales';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

type Option = { label: string; value: string };

interface Props {
  gridElement: MediaGrid;
}

function LocaleControl({ gridElement }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { locale } = gridElement.grid;

  const updateLocale = (locale: string) => {
    void downloadLokalizeLanguage(locale).then(() => dispatch(updateAllGridElementsOperation({ locale })));
  };

  useEffect(() => {
    locale !== i18n.language && updateLocale(locale);
  }, []);

  const downloadLokalizeLanguage = (language: string = 'en') => {
    const i18nInstance = i18n.cloneInstance();
    return i18nInstance.changeLanguage(browserLocaleToEditorLocale[language]);
  };

  const onSelect = useCallback((option: SingleValue<Option>) => {
    if (option) {
      updateLocale(option.value);
    }
  }, []);

  const options = useMemo(() => getLocaleList(i18n), [i18n.language]);

  const selectedOption = useMemo(() => options.find(({ value }) => value === locale), [options, locale]);

  return (
    <>
      <PropertySeparator bigMargin />
      <div className={controlStyles.controlTitle}>{t('language')}</div>
      <Select placeholder="" options={options} onChange={onSelect} value={selectedOption} className="cy-grid-locale" />
    </>
  );
}

export default React.memo(LocaleControl);
