import { GalleryImage } from 'editor/src/store/gallery/types';

/**
 * Grouping images by date and location
 * @param images - The images to group
 * @param groupsNumber - Max number of groups
 * @returns The grouped images
 */
function groupImages(images: GalleryImage[], groupsNumber: number): GalleryImage[][] {
  let remainedImagesNumber = images.length;
  // Separate images with and without dates
  const imagesWithDates: GalleryImage[] = [];
  const imagesWithoutDates: GalleryImage[] = [];

  images.forEach((img) => {
    if (img.exif?.dateCreated) {
      imagesWithDates.push(img);
    } else {
      imagesWithoutDates.push(img);
    }
  });

  // Sort images with dates chronologically
  const sortedImages = [...imagesWithDates].sort((a, b) => {
    const dateA = new Date(a.exif!.dateCreated ?? '').getTime();
    const dateB = new Date(b.exif!.dateCreated ?? '').getTime();
    return dateA - dateB;
  });

  // Group images with dates by day and location
  const groupedByDay = sortedImages.reduce<{ [key: string]: GalleryImage[] }>((groups, img) => {
    const date = new Date(img.exif!.dateCreated ?? '');
    const dayKey = date.toISOString().split('T')[0];

    if (!groups[dayKey]) {
      groups[dayKey] = [];
    }
    groups[dayKey].push(img);
    return groups;
  }, {});

  const result: GalleryImage[][] = [];

  // Process images with dates
  Object.values(groupedByDay).forEach((dayImages) => {
    // Group by location using approximate distance
    const locationGroups: GalleryImage[][] = [];
    let currentGroup: GalleryImage[] = [];

    dayImages.forEach((img) => {
      if (currentGroup.length === 0) {
        currentGroup.push(img);
        remainedImagesNumber -= 1;
      } else {
        const prevImg = currentGroup[currentGroup.length - 1];
        const isSameLocation = areImagesNearby(prevImg, img);
        const maxImagePerGroup = Math.ceil(
          remainedImagesNumber / (groupsNumber - locationGroups.length - result.length),
        );

        if (isSameLocation && currentGroup.length < maxImagePerGroup) {
          currentGroup.push(img);
          remainedImagesNumber -= 1;
        } else {
          locationGroups.push([...currentGroup]);
          currentGroup = [img];
          remainedImagesNumber -= 1;
        }
      }
    });

    if (currentGroup.length > 0) {
      locationGroups.push(currentGroup);
    }

    result.push(...locationGroups);
  });

  // Process images without dates in groups
  let maxImagePerGroup = Math.ceil(imagesWithoutDates.length / (groupsNumber - result.length));
  for (let i = 0; i < imagesWithoutDates.length; i += maxImagePerGroup) {
    const group = imagesWithoutDates.slice(i, i + maxImagePerGroup);
    result.push(group);
    maxImagePerGroup = Math.ceil((imagesWithoutDates.length - i - 1) / (groupsNumber - result.length));
  }

  return result;
}

/**
 * Helper function to check if two images were taken nearby (within ~1km)
 * @param img1 - The first image
 * @param img2 - The second image
 * @returns Whether the images were taken nearby
 */
function areImagesNearby(img1: GalleryImage, img2: GalleryImage): boolean {
  if (!img1.exif?.location || !img2.exif?.location) {
    return true;
  }

  const lat1 = img1.exif.location.latitude;
  const lon1 = img1.exif.location.longitude;
  const lat2 = img2.exif.location.latitude;
  const lon2 = img2.exif.location.longitude;

  // Approximate distance calculation using Haversine formula
  const R = 6371; // Earth's radius in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance <= 1; // Consider images within 1km as nearby
}

export default groupImages;
