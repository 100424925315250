import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import setMobileViewOperation from 'editor/src/store/app/operation/setMobileViewOperation';
import autoFillOperation from 'editor/src/store/editor/operation/autoFillOperation';
import isAllImagesUsed from 'editor/src/store/editor/selector/isAllImagesUsed';
import isAutofillAllowed from 'editor/src/store/editor/selector/isAutofillAllowed';
import isUseNotUploadedImagesAllowed from 'editor/src/store/editor/selector/isUseNotUploadedImagesAllowed';
import areAllImagesUploaded from 'editor/src/store/gallery/selector/areAllImagesUploaded';
import getGalleryImagesCount from 'editor/src/store/gallery/selector/getGalleryImagesCount';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { AutofillMarginOption } from 'editor/src/component/Autofill/AutofillOptions';
import AutofillMobileMenu from 'editor/src/component/BottomBarMobile/ActionBarSection/AutofillMobileMenu';
import Button from 'editor/src/component/Button';
import { PopupName } from 'editor/src/component/Popup/popups';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import useMobileMenu from 'editor/src/component/useMobileMenu';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  onAutofillCb?: () => void;
}

const LOADING_IMAGES_TIMEOUT = 2000;

function ButtonAutoFill({ onAutofillCb }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    noUnusedImages,
    autofillAllowed,
    imageCount,
    allowedUseNotUploadedImages,
    allImagesUploaded,
    useDynamicLayout,
  } = useSelector(
    (state) => ({
      autofillAllowed: isAutofillAllowed(state),
      noUnusedImages: isAllImagesUsed(state),
      imageCount: getGalleryImagesCount(state),
      allowedUseNotUploadedImages: isUseNotUploadedImagesAllowed(state),
      allImagesUploaded: areAllImagesUploaded(state),
      useDynamicLayout: state.hostSettings.useDynamicLayout,
    }),
    shallowEqual,
  );
  const loadingImagesTimeout = useRef<any | undefined>(undefined);
  const [loadingImages, setLoadingImages] = useState(false);
  const [previousImageCount, setPreviousImageCount] = useState(imageCount);
  const mobileMenu = useMobileMenu({ addBackdrop: true, forceHalfScreen: false });
  const isMobile = useIsMobile();

  useEffect(() => {
    setPreviousImageCount(imageCount);
    if (loadingImagesTimeout.current) {
      clearTimeout(loadingImagesTimeout.current);
    }
    if (previousImageCount < imageCount) {
      setLoadingImages(true);
      loadingImagesTimeout.current = setTimeout(() => {
        setLoadingImages(false);
      }, LOADING_IMAGES_TIMEOUT);

      return () => clearTimeout(loadingImagesTimeout.current);
    }

    return undefined;
  }, [imageCount]);

  if (!autofillAllowed) {
    return null;
  }

  const onClick = () => {
    if (!useDynamicLayout) {
      dispatch(autoFillOperation());
      onAutofillCb?.();
      return;
    }
    if (isMobile) {
      mobileMenu.open();
    } else {
      dispatch(openPopupOperation(PopupName.AUTO_FILL_POPUP));
      onAutofillCb?.();
    }
  };

  const onContinue = (options: AutofillMarginOption) => {
    dispatch(autoFillOperation(options));
    if (isMobile) {
      dispatch(setMobileViewOperation('spreads'));
    }
    onAutofillCb?.();
  };

  let tooltipKey = 'editor-fill-design-with-images';
  if (loadingImages) {
    tooltipKey = 'editor-wait-for-generating-thumbnails';
  }
  if (noUnusedImages) {
    tooltipKey = 'editor-no-unused-images';
  }
  if (imageCount === 0) {
    tooltipKey = 'editor-no-images';
  }
  if (!allowedUseNotUploadedImages && !allImagesUploaded) {
    tooltipKey = 'editor-uploading-...';
  }

  return (
    <WithTooltip overlay={t(tooltipKey)} className={cn(styles.autoFillButtonWrapper, 'cy-autofill-btn')}>
      <Button
        secondary
        stopPropagation
        className={styles.buttonAutoFill}
        disabled={noUnusedImages || loadingImages || !(allowedUseNotUploadedImages || allImagesUploaded)}
        onClick={onClick}
      >
        <div className={styles.icon} />
        <div className={styles.autoFillLabelWrapper}>
          <span className={styles.title}>{t('editor-auto-fill')}</span>
        </div>
      </Button>
      {mobileMenu.render(<AutofillMobileMenu onCancel={mobileMenu.close} onContinue={onContinue} />)}
    </WithTooltip>
  );
}

export default React.memo(ButtonAutoFill);
