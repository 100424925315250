import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { useStore } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';

interface Props {
  className?: string;
  onClickCallback?: () => void;
}

function SwitchTemplateButton({ className, onClickCallback }: Props) {
  const { t } = useTranslation();
  const store = useStore();

  const onCustomEventBtnClick = () => {
    const state = store.getState();
    const currentDesignData = getDesignData(state);

    currentDesignData && sendPostMessage('editor.triggerSwitchDesignTemplate', currentDesignData);
    onClickCallback?.();
  };

  return (
    <Button
      secondary
      stopPropagation
      onClick={onCustomEventBtnClick}
      className={cn(className, 'cy-button-switch-template', 'gelato-editor-custom-button')}
    >
      {t('Change template')}
    </Button>
  );
}

export default React.memo(SwitchTemplateButton);
