import { ButtonMode, MenuItem, MenuItemProps } from 'editor/src/component/Menu/type';

import DesignSavingStatus, { canShow as savingStatusCanShow } from '../DesignSavingStatus';

import Button3dPreview, { canShow as preview3dCanShow } from './buttons/Button3dPreview';
import ButtonAcceptModifiedImage from './buttons/ButtonAcceptModifiedImage';
import ButtonAddLayer, { canShowInTopBar as addCanShow } from './buttons/ButtonAddLayer';
import ButtonBackgroundColor, { canShow as backgroundColorCanShow } from './buttons/ButtonBackgroundColor';
import ButtonCrop, { canShow as cropCanShow } from './buttons/ButtonCrop';
import ButtonCropDone, { canShow as cropDoneCanShow } from './buttons/ButtonCropDone';
import ButtonCropRotate90, { canShow as cropRotateCanShow } from './buttons/ButtonCropRotate90';
import { ButtonCropZoom, ButtonCropZoomDropDown, canShow as cropZoomCanShow } from './buttons/ButtonCropZoom';
import ButtonDeclineModifiedImage from './buttons/ButtonDeclineModifiedImage';
import ButtonDownload, { canShow as buttonDownloadCanShow } from './buttons/ButtonDownload';
import ButtonDuplicate, { canShow as duplicateCanShow } from './buttons/ButtonDuplicate';
import ButtonEditImage, { canShow as buttonEditImageCanShow } from './buttons/ButtonEditImage';
import ButtonEffects, { canShow as buttonEffectsCanShow } from './buttons/ButtonEffects';
import ButtonFill, { canShowInTopBar as fillCanShow } from './buttons/ButtonFill';
import ButtonFit, { canShowInTopBar as fitCanShow } from './buttons/ButtonFit';
import ButtonFlatPreview, { canShow as flatPreviewCanShow } from './buttons/ButtonFlatPreview';
import ButtonFlipX, { canShow as flipXCanShow } from './buttons/ButtonFlipX';
import ButtonFlipY, { canShow as flipYCanShow } from './buttons/ButtonFlipY';
import ButtonFoilLayers, { canShow as foilLayersCanShow } from './buttons/ButtonFoilLayers';
import ButtonFontColor, { canShow as fontColorCanShow } from './buttons/ButtonFontColor';
import ButtonFontFamily, { canShow as fontFamilyCanShow } from './buttons/ButtonFontFamily';
import ButtonFontSize, { canShow as fontSizeCanShow } from './buttons/ButtonFontSize';
import ButtonGrids, { canShow as gridsCanShow } from './buttons/ButtonGrids';
import ButtonImageColor, { canShow as imageColorCanShow } from './buttons/ButtonImageColor';
import ButtonImageEffectsDropDown, { canShow as imageEffectsCanShow } from './buttons/ButtonImageEffectsDropDown';
import ButtonImageEffects from './buttons/ButtonImageEffectsDropDown/ButtonImageEffects';
import ButtonImageShadow, { canShow as buttonImageShadowCanShow } from './buttons/ButtonImageShadow';
import { ButtonLineEdge1, ButtonLineEdge1DropDown, canShow as buttonLineEdge1CanShow } from './buttons/ButtonLineEdge1';
import { ButtonLineEdge2, ButtonLineEdge2DropDown, canShow as buttonLineEdge2CanShow } from './buttons/ButtonLineEdge2';
import ButtonLineStrokeColor, { canShow as lineStrokeColorCanShow } from './buttons/ButtonLineStrokeColor';
import {
  ButtonLineStyles,
  ButtonLineStylesDropDown,
  canShow as buttonLineStylesCanShow,
} from './buttons/ButtonLineStyles';
import ButtonLock, { canShow as lockCanShow } from './buttons/ButtonLock';
import ButtonMockupPreview, { canShow as buttonMockupPreviewCanShow } from './buttons/ButtonMockupPreview';
import ButtonPattern, { canShow as buttonPatternCanShow } from './buttons/ButtonPattern';
import {
  ButtonPersonalization,
  ButtonPersonalizationDropDown,
  canShow as personalizationCanShow,
} from './buttons/ButtonPersonalization';
import ButtonPerspectiveLines, { canShow as buttonPerspectiveLinesCanShow } from './buttons/ButtonPerspectiveLines';
import ButtonPerspectiveTransform, {
  canShow as perspectiveTransformCanShow,
} from './buttons/ButtonPerspectiveTransform';
import ButtonPerspectiveTransformDone, {
  canShow as perspectiveTranformDoneCanShow,
} from './buttons/ButtonPerspectiveTransformDone';
import { ButtonPosition, ButtonPositionDropDown, canShow as positionCanShow } from './buttons/ButtonPosition';
import ButtonProductFitting, {
  ButtonProductAreaDropDown,
  canShow as productAreaCanShow,
} from './buttons/ButtonProductFitting';
import ButtonProperties, { canShowInTopBar as propertiesCanShow } from './buttons/ButtonProperties';
import ButtonRectangleFillColor, { canShow as rectangleFillColorCanShow } from './buttons/ButtonRectangleFillColor';
import ButtonRectangleStrokeColor, {
  canShow as rectangleStrokeColorCanShow,
} from './buttons/ButtonRectangleStrokeColor';
import {
  ButtonRectangleStyles,
  ButtonRectangleStylesDropDown,
  canShow as buttonRectangleStylesCanShow,
} from './buttons/ButtonRectangleStyles';
import ButtonRemove, { canShow as removeCanShow } from './buttons/ButtonRemove';
import ButtonRolls, { canShow as buttonRollCanShow } from './buttons/ButtonRolls';
import ButtonRulers, { canShow as buttonRulersCanShow } from './buttons/ButtonRulers';
import ButtonSaveDraft, { canShow as saveDraftCanShow } from './buttons/ButtonSaveDraft';
import ButtonSaveTemplate, { canShow as saveTemplatesCanShow } from './buttons/ButtonSaveTemplate';
import ButtonStatic, { canShow as staticCanShow } from './buttons/ButtonStatic';
import ButtonStretchToFit, { canShowInTopBar as resizeToFitCanShow } from './buttons/ButtonStretchToFit';
import ButtonStrikethrough from './buttons/ButtonStrikethrough';
import ButtonSwitchTemplate, { canShow as switchTemplateCanShow } from './buttons/ButtonSwitchTemplate';
import {
  ButtonTextAlignment,
  ButtonTextAlignmentDropDown,
  canShow as textAlignmentCanShow,
} from './buttons/ButtonTextAlignment';
import {
  ButtonTextFitting,
  ButtonTextFittingDropDown,
  canShow as textWrappingCanShow,
} from './buttons/ButtonTextFitting';
import {
  ButtonTextSpacing,
  ButtonTextSpacingDropDown,
  canShow as textSpacingCanShow,
} from './buttons/ButtonTextSpacing';
import ButtonThreadColor, { canShow as threadColorCanShow } from './buttons/ButtonThreadColor';
import ButtonUnderline from './buttons/ButtonUnderline';
import ButtonUndoRedo from './buttons/ButtonUndoRedo';
import ButtonWarnings, { canShow as warningsCanShow } from './buttons/ButtonWarnings';
import SplitterHorizontal from './buttons/SplitterHorizontal';
import SplitterVertical from './buttons/SplitterVertical';

export function PLACE_HOLDER() {
  return null;
}

const alwaysShow = () => true;

export interface DesktopMenuItem extends MenuItem {
  DropDown?: React.FC<MenuItemProps>;
  dropDownMode?: ButtonMode;
  weight: number;
}

const ALL_FIRST: DesktopMenuItem[] = [
  { Component: DesignSavingStatus, canShow: savingStatusCanShow, weight: 3 },
  { Component: ButtonSaveDraft, canShow: saveDraftCanShow, weight: 3 },
  { Component: ButtonUndoRedo, canShow: alwaysShow, weight: 3 },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 3,
  },
  {
    Component: ButtonBackgroundColor,
    canShow: backgroundColorCanShow,
    weight: 3,
  },
  {
    Component: SplitterVertical,
    canShow: backgroundColorCanShow,
    DropDown: SplitterHorizontal,
    weight: 3,
  },
  { Component: ButtonRulers, canShow: buttonRulersCanShow, weight: 3 },
  { Component: ButtonRolls, canShow: buttonRollCanShow, weight: 3 },
  {
    Component: ButtonPerspectiveLines,
    canShow: buttonPerspectiveLinesCanShow,
    weight: 3,
  },
];

const ALL_END: DesktopMenuItem[] = [
  {
    Component: PLACE_HOLDER,
    weight: 10,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
  },
  { Component: ButtonStatic, weight: 1, canShow: staticCanShow },
  { Component: ButtonLock, weight: 1, canShow: lockCanShow },
  { Component: ButtonDuplicate, weight: 1, canShow: duplicateCanShow },
  {
    Component: ButtonWarnings,
    mode: 'icon',
    weight: 4,
    canShow: warningsCanShow,
  },
  { Component: ButtonRemove, weight: 4, canShow: removeCanShow },
  {
    Component: ButtonFoilLayers,
    mode: 'label-icon',
    weight: 4,
    canShow: foilLayersCanShow,
  },
  {
    Component: Button3dPreview,
    mode: 'label-icon',
    weight: 3,
    canShow: preview3dCanShow,
  },
  {
    Component: ButtonFlatPreview,
    mode: 'label-icon',
    weight: 3,
    canShow: flatPreviewCanShow,
  },
  {
    Component: ButtonMockupPreview,
    mode: 'label-icon',
    weight: 3,
    canShow: buttonMockupPreviewCanShow,
  },
  {
    Component: ButtonDownload,
    mode: 'label-icon',
    weight: 3,
    canShow: buttonDownloadCanShow,
  },
  { Component: ButtonAddLayer, weight: 4, canShow: addCanShow },
  { Component: ButtonSaveTemplate, weight: 3, canShow: saveTemplatesCanShow },
  { Component: ButtonSwitchTemplate, weight: 3, canShow: switchTemplateCanShow },
];

export const DEFAULT_ELTS: DesktopMenuItem[] = [...ALL_FIRST, ...ALL_END];

export const CROP_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  {
    Component: ButtonCropDone,
    mode: 'label',
    canShow: cropDoneCanShow,
    weight: 2,
  },
  {
    Component: ButtonCropZoomDropDown,
    canShow: cropZoomCanShow,
    weight: 2,
    DropDown: ButtonCropZoom,
  },
  { Component: ButtonCropRotate90, canShow: cropRotateCanShow, weight: 2 },
  {
    Component: ButtonStretchToFit,
    mode: 'label',
    canShow: resizeToFitCanShow,
    weight: 2,
  },
  ...ALL_END,
];

export const PERSPECTIVE_TRANSFORM_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  {
    Component: ButtonPerspectiveTransformDone,
    mode: 'label',
    canShow: perspectiveTranformDoneCanShow,
    weight: 2,
  },
  ...ALL_END,
];

export const PLUGIN_PREVIEW_MODE_ELTS: DesktopMenuItem[] = [
  {
    Component: ButtonAcceptModifiedImage,
    mode: 'label-icon',
    canShow: () => true,
    weight: 2,
  },
  {
    Component: ButtonDeclineModifiedImage,
    mode: 'label-icon',
    canShow: () => true,
    weight: 2,
  },
];

export const IMAGE_SELECTED_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonImageColor,
    mode: 'icon',
    canShow: imageColorCanShow,
    weight: 2,
  },
  {
    Component: SplitterVertical,
    canShow: imageColorCanShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonThreadColor,
    mode: 'label-icon',
    canShow: threadColorCanShow,
    weight: 2,
  },
  {
    Component: SplitterVertical,
    canShow: threadColorCanShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  { Component: ButtonFill, canShow: fillCanShow, weight: 2 },
  { Component: ButtonFit, canShow: fitCanShow, weight: 2 },
  {
    Component: ButtonCropZoomDropDown,
    canShow: cropZoomCanShow,
    weight: 2,
    DropDown: ButtonCropZoom,
  },
  { Component: ButtonCropRotate90, canShow: cropRotateCanShow, weight: 2 },
  {
    Component: ButtonFlipX,
    mode: 'icon',
    canShow: flipXCanShow,
    weight: 2,
  },
  {
    Component: ButtonFlipY,
    mode: 'icon',
    canShow: flipYCanShow,
    weight: 2,
  },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonImageEffectsDropDown,
    mode: 'label',
    canShow: imageEffectsCanShow,
    weight: 2,
    DropDown: ButtonImageEffects,
  },
  {
    Component: ButtonEditImage,
    mode: 'label',
    canShow: buttonEditImageCanShow,
    weight: 2,
  },
  {
    Component: ButtonPattern,
    mode: 'label',
    canShow: buttonPatternCanShow,
    weight: 2,
  },
  {
    Component: ButtonCrop,
    mode: 'label',
    canShow: cropCanShow,
    weight: 2,
  },
  {
    Component: ButtonPositionDropDown,
    mode: 'label',
    canShow: positionCanShow,
    weight: 2,
    DropDown: ButtonPosition,
  },
  {
    Component: ButtonProperties,
    mode: 'label',
    canShow: propertiesCanShow,
    weight: 2,
  },
  {
    Component: ButtonPersonalizationDropDown,
    mode: 'label',
    canShow: personalizationCanShow,
    weight: 2,
    DropDown: ButtonPersonalization,
  },
  {
    Component: ButtonPerspectiveTransform,
    mode: 'label',
    canShow: perspectiveTransformCanShow,
    weight: 2,
  },
  {
    Component: ButtonImageShadow,
    mode: 'label',
    canShow: buttonImageShadowCanShow,
    weight: 2,
  },
  {
    Component: ButtonProductAreaDropDown,
    mode: 'label',
    canShow: productAreaCanShow,
    weight: 2,
    DropDown: ButtonProductFitting,
  },
  ...ALL_END,
];

export const ADDON_SELECTED_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  { Component: ButtonFill, canShow: fillCanShow, weight: 2 },
  { Component: ButtonFit, canShow: fitCanShow, weight: 2 },
  {
    Component: ButtonCropZoomDropDown,
    canShow: cropZoomCanShow,
    weight: 2,
    DropDown: ButtonCropZoom,
  },
  { Component: ButtonCropRotate90, canShow: cropRotateCanShow, weight: 2 },
  {
    Component: ButtonFlipX,
    mode: 'icon',
    canShow: flipXCanShow,
    weight: 2,
  },
  {
    Component: ButtonFlipY,
    mode: 'icon',
    canShow: flipYCanShow,
    weight: 2,
  },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonCrop,
    mode: 'label',
    canShow: cropCanShow,
    weight: 2,
  },
  {
    Component: ButtonPerspectiveTransform,
    mode: 'label',
    canShow: perspectiveTransformCanShow,
    weight: 2,
  },
  {
    Component: ButtonPositionDropDown,
    mode: 'label',
    canShow: positionCanShow,
    weight: 2,
    DropDown: ButtonPosition,
  },
  {
    Component: ButtonProperties,
    mode: 'label',
    canShow: propertiesCanShow,
    weight: 2,
  },
  {
    Component: ButtonPersonalization,
    mode: 'label',
    canShow: personalizationCanShow,
    weight: 2,
  },
  ...ALL_END,
];

export const TEXT_SELECTED_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  { Component: ButtonFontFamily, canShow: fontFamilyCanShow, weight: 2 },
  { Component: ButtonFontSize, canShow: fontSizeCanShow, weight: 2 },
  { Component: ButtonFontColor, canShow: fontColorCanShow, weight: 2 },
  { Component: ButtonTextFittingDropDown, DropDown: ButtonTextFitting, canShow: textWrappingCanShow, weight: 2 },
  { Component: ButtonUnderline, canShow: fontColorCanShow, weight: 2 },
  { Component: ButtonStrikethrough, canShow: fontColorCanShow, weight: 2 },
  {
    Component: ButtonTextAlignmentDropDown,
    canShow: textAlignmentCanShow,
    weight: 2,
    DropDown: ButtonTextAlignment,
  },
  {
    Component: ButtonTextSpacingDropDown,
    canShow: textSpacingCanShow,
    weight: 2,
    DropDown: ButtonTextSpacing,
  },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonEffects,
    mode: 'label',
    canShow: buttonEffectsCanShow,
    weight: 2,
  },
  {
    Component: ButtonPositionDropDown,
    mode: 'label',
    canShow: positionCanShow,
    weight: 2,
    DropDown: ButtonPosition,
  },
  {
    Component: ButtonProperties,
    mode: 'label',
    canShow: propertiesCanShow,
    weight: 2,
  },
  {
    Component: ButtonPersonalizationDropDown,
    mode: 'label',
    canShow: personalizationCanShow,
    weight: 2,
    DropDown: ButtonPersonalization,
  },
  ...ALL_END,
];

export const GRID_SELECTED_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  {
    Component: ButtonGrids,
    canShow: gridsCanShow,
    weight: 2,
  },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonPositionDropDown,
    mode: 'label',
    canShow: positionCanShow,
    weight: 2,
    DropDown: ButtonPosition,
  },
  {
    Component: ButtonProperties,
    mode: 'label',
    canShow: propertiesCanShow,
    weight: 2,
  },
  ...ALL_END,
];

export const LINE_SELECTED_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  {
    Component: ButtonLineStrokeColor,
    canShow: lineStrokeColorCanShow,
    weight: 2,
  },
  {
    Component: ButtonLineStylesDropDown,
    canShow: buttonLineStylesCanShow,
    weight: 2,
    DropDown: ButtonLineStyles,
  },
  {
    Component: ButtonLineEdge1DropDown,
    canShow: buttonLineEdge1CanShow,
    weight: 2,
    DropDown: ButtonLineEdge1,
  },
  {
    Component: ButtonLineEdge2DropDown,
    canShow: buttonLineEdge2CanShow,
    weight: 2,
    DropDown: ButtonLineEdge2,
  },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonPositionDropDown,
    mode: 'label',
    canShow: positionCanShow,
    weight: 2,
    DropDown: ButtonPosition,
  },
  {
    Component: ButtonPersonalizationDropDown,
    mode: 'label',
    canShow: personalizationCanShow,
    weight: 2,
    DropDown: ButtonPersonalization,
  },
  ...ALL_END,
];

export const RECTANGLE_SELECTED_ELTS: DesktopMenuItem[] = [
  ...ALL_FIRST,
  {
    Component: ButtonRectangleFillColor,
    canShow: rectangleFillColorCanShow,
    weight: 2,
  },
  {
    Component: ButtonRectangleStrokeColor,
    canShow: rectangleStrokeColorCanShow,
    weight: 2,
  },
  {
    Component: ButtonRectangleStylesDropDown,
    canShow: buttonRectangleStylesCanShow,
    weight: 2,
    DropDown: ButtonRectangleStyles,
  },
  {
    Component: SplitterVertical,
    canShow: alwaysShow,
    DropDown: SplitterHorizontal,
    weight: 2,
  },
  {
    Component: ButtonPositionDropDown,
    mode: 'label',
    canShow: positionCanShow,
    weight: 2,
    DropDown: ButtonPosition,
  },
  {
    Component: ButtonProperties,
    mode: 'label',
    canShow: propertiesCanShow,
    weight: 2,
  },
  {
    Component: ButtonPersonalizationDropDown,
    mode: 'label',
    canShow: personalizationCanShow,
    weight: 2,
    DropDown: ButtonPersonalization,
  },
  ...ALL_END,
];
