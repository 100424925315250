import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import noTemplatesIcon from 'editor/src/static/no-templates.svg';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconRight from 'editor/src/component/Icon/IconRight';

import styles from './index.module.scss';

const learnHowLink = 'https://support.gelato.com/en/';

function EmptyTemplatesPage() {
  const { t } = useTranslation();
  return (
    <>
      <TabContentHeader title={t('Templates')} />
      <ContentBlock className={cn(styles.wrapper, 'cy-empty-template-page')}>
        <div>
          <img src={noTemplatesIcon} alt={t('No saved designs yet in templates yet')} draggable={false} />
        </div>
        <div className={styles.title}>{t('No saved designs yet in templates yet')}</div>
        <div className={styles.subtitle}>{t('Save time be reusing your designs in different products.')}</div>
        <a className={styles.link} href={learnHowLink} target="_blank" rel="noreferrer">
          {t('Learn how')}&nbsp;
          <IconRight />
        </a>
      </ContentBlock>
    </>
  );
}

export default React.memo(EmptyTemplatesPage);
