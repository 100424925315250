import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageByElementAddress from 'editor/src/store/gallery/selector/getImageByElementAddress';
import { ImageState } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName } from 'editor/src/store/plugins/types';

import canUseFiltersOnImage from 'editor/src/util/plugins/canUseFiltersOnImage';

import IconFilters from 'editor/src/component/Icon/IconFilters';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import usePluginButton from 'editor/src/component/TopMenuDesktop/usePluginButton';

import FiltersMobileMenu from '../../BottomBarMobile/ActionBarSection/FiltersMobileMenu';
import useMobileMenu from '../../useMobileMenu';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, menuContext) => canUseFiltersOnImage(menuContext);
export const canShowInTopBar: CanShow = (state, menuContext) =>
  !state.hostSettings.editImageFlag && canShow(state, menuContext);

function ButtonFilters({ mode, close, isMobile, context }: MenuItemProps) {
  const dispatch = useDispatch();
  const onActivatePlugin = () => dispatch(setActiveTopMenuDropdownOperation(undefined, false));
  const plugin = useSelector((state) => getPlugin(state, PluginName.Filters));
  const { isActive, activatePluginTab, premiumEnabled, PremiumIcon } = usePluginButton({
    close,
    isMobile,
    plugin,
    tabName: TAB_NAMES.FILTERS,
    onActivatePlugin,
  });
  const selectedImage = useSelector((state) => getImageByElementAddress(state, context.structureIndexes[0]));
  const filtersMenu = useMobileMenu({ addBackdrop: false, forceHalfScreen: true });

  function handleClick() {
    if (isMobile) {
      filtersMenu.open();
    } else {
      activatePluginTab();
    }
  }

  return (
    <>
      <MenuButton
        Icon={IconFilters}
        on={isActive}
        mode={mode}
        handleClick={handleClick}
        i18nKey="editor-filters"
        className="cy-button-filters"
        premium={premiumEnabled}
        PremiumIcon={PremiumIcon}
        disabled={selectedImage?.state !== ImageState.UPLOADED}
      />
      {filtersMenu.render(<FiltersMobileMenu closeMenuCb={filtersMenu.close} />)}
    </>
  );
}

export default React.memo(ButtonFilters);
