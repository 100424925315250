import addMediaElementOperation from 'editor/src/store/design/operation/addMediaElementOperation';
import { DesignData, MediaImage } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';
import { elementUuids } from 'editor/src/util/uuids';

import createPageLayout, { mirrorLayout } from './createPageLayout';
import groupImages from './groupImages';

const applyDynamicLayout =
  (designData: DesignData, galleryImages: GalleryImage[], spreadIndexesToIgnore: number[], margin: number) =>
  (dispatch: ThunkDispatch) => {
    const pageDimensions = designData.spreads[1].pages[0];
    const pagesNumber = designData.spreads
      .filter((spread, key) => !spreadIndexesToIgnore.includes(key))
      .flatMap(({ pages }) => pages.filter(({ groups }) => !groups.blank)).length;
    const groupedImages = groupImages(galleryImages, pagesNumber);

    for (let i = 0; i < designData.spreads.length; i += 1) {
      if (spreadIndexesToIgnore.includes(i)) {
        continue;
      }

      // Track used layout direction for this spread
      const usedLayoutKeys = new Set<string>();

      // eslint-disable-next-line no-restricted-syntax
      for (const page of designData.spreads[i].pages) {
        if (!page.groups.mediabox?.length || (page.groups.media?.length ?? 0) > 0) {
          continue;
        }

        const imageGroup = groupedImages.shift();
        if (!imageGroup) {
          break;
        }

        const pageLayout = createPageLayout(imageGroup, pageDimensions, { margin });

        // when the layout direction is already used in this spread, mirror it for visual beauty
        let finalLayout = pageLayout;
        if (usedLayoutKeys.has(pageLayout.direction)) {
          finalLayout = mirrorLayout(pageLayout, page.width, page.height);
        }
        usedLayoutKeys.add(pageLayout.direction);

        finalLayout.slots.forEach((slot) => {
          const { image, x, y, width, height } = slot;

          const imageElement: MediaImage = {
            type: 'image',
            group: '',
            name: image.name,
            x: page.x + x,
            y: page.y + y,
            width,
            height,
            r: 0,
            url: image.url,
            px: 0,
            py: 0,
            pw: width,
            ph: height,
            pr: 0,
            uuid: elementUuids.generate(),
            imageId: image.id,
            locked: undefined,
          };

          const { px, py, pw, ph } = getCenteredImagePosition(imageElement, image);
          imageElement.px = px;
          imageElement.py = py;
          imageElement.pw = pw;
          imageElement.ph = ph;

          dispatch(addMediaElementOperation(i, 0, imageElement));
        });
      }

      if (!groupedImages.length) {
        break;
      }
    }
  };

export default applyDynamicLayout;
